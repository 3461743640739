import { icons } from '../../../assets/svg/icons/index';

const ItemIcon = ({ type, content_type }) => {
  const contentType =
  content_type && type !== "directory" && type !== "symlink"
    ? content_type.split("/")[1].toUpperCase()
    : type === "directory" || type === "symlink"
      ? type
      : "-";

  if (type === "directory") {
    return icons["Directory"];
  }
  else if (contentType === "PLAIN") {
    return icons["FILE"];
  }
  else if (contentType === "symlink") {
    return icons["LINK"];
  }
  else if (icons[contentType] === undefined) {
    return icons["FILE"];
  }
  else {
    return icons[contentType];
  }
};

export default ItemIcon;