import backendTranslation from './../../main/utils/backendTranslation';

export const parseHints = (hints) => {
  const viewsArray =
    (hints &&
      typeof hints === "object"
      ?
      Object.entries(hints).map((hint) => {
        const [ key, view ] = hint;
        return {
          label: hint.label ? backendTranslation(hint.label) : key,
          ...view,
        };
      }) : hints) ?? [];
  if (viewsArray) {
    viewsArray.sort((a, b) => {
      if (!a?.priority) return -1;
      if (!b?.priority) return 1;
      return a.priority - b.priority;
    });
  }
  return viewsArray;
};