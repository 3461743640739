import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useIBIS } from '../../hooks/useIbis';
import { Button } from './button';

import { ReactComponent as OverwriteIcon } from '../../../assets/svg/icons/download.svg';

const Overwrite = ({ setFileModal }) => {
  const IBIS = useIBIS();
  const { t } = useTranslation();

  const openModal = (e) => {
    e.stopPropagation();
    setFileModal();
  };

  return IBIS.node ?
    <Button type='secondary' className='ml-2' onClick={openModal}
      icon={<OverwriteIcon style={{ transform: "rotate(180deg)" }} />} >
      {t('ibis-ui:overwrite')}
    </Button>
    : null;
};

Overwrite.propTypes = {
  setFileModal: PropTypes.func,
  setEditFileModal: PropTypes.func,
};

export default Overwrite;
