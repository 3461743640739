import * as ibis from './client';
import { __env } from '../../envloader';
import axiosInstance from '@axios';
import { getGV } from '../../main/utils/globalVariablesProvider';

function getSecret() {
  return getGV("secret");
}

let client = new ibis.Client({
  tenantUrl: __env.IBIS_TENANT_URL,
  axiosInstance: axiosInstance,
  getSecret: getSecret,
});

export function setClient(newClient) {
  client = newClient;
}

export function fetchDirectory({ path, cursor, fetchAll, pageSize }) {
  const query = cursor ? cursor : pageSize ? { page_size: pageSize } : null;
  return client.fetchDirectory({
    path: path,
    query: query,
    fetchAll: fetchAll,
  });
}

export const fetchFile = (args) => client.fetchFile(args);

export const getEmbedContent = client.getEmbedContent;

export const touch = client.touch;

export const fetchContentType = client.fetchContentType;

export const createNode = (args) => {
  return client.createNode(args);
};

export function createDirectory({ path, attributes }) {
  return createNode({ path, node: { attributes } });
}

export const createSymlink = (args) => client.createNode({
  ...args,
  headers: {
    "IBIS-TARGET": args.target,
    "IBIS-TYPE": "symlink",
  }
});

export const createRellink = (args) => client.createNode({
  ...args,
  headers: {
    "IBIS-TARGET": args.target,
    "IBIS-ATTRIBUTES": `relation=${args.relation}`,
  }
});
export function createFile({ path, file, onUploadProgress, signal }) {
  let formData = new FormData();
  formData.append("content", file);

  return createNode({
    path,
    headers: {
      "content-type": "multipart/form-data"
    },
    method: "post",
    ...(file && {
      node: formData,
      onUploadProgress,
      signal,
    })
  });
}

export function patchFile({ path, file, onUploadProgress, signal }) {
  let formData = new FormData();
  formData.append("content", file);

  return createNode({
    path,
    method: "put",
    ...(file && {
      node: formData,
      onUploadProgress,
      contentType: "multipart/form-data",
      signal,
    })
  });
}

export function patchDirectory({ path, attributes }) {
  return createNode({ path, method: "patch", node: { attributes } });
}

export const _delete = (args) => client.deleteNode(args);

export const getEmbedLink = (path) => client.sendRequest({
  method: "get",
  path: path,
  query: {
    embed: "parent",
    redirect: "explicit",
  },
  __silentFor: [ { status: 404 }, { status: 403 } ],
});

export function patchIBISfile({ path, content }) {
  const directory = client.bindDirectory(
    path.substring(0, path.lastIndexOf("/") + 1)
  );
  const name = path.substring(path.lastIndexOf("/") + 1);
  const file = directory.bind({
    attributes: {},
    content: content,
    content_type: "application/json",
    name: name,
    size: "",
    user_content_last_modified: "",
    type: "file",
  });

  return file.patch().then((response) => {
    return response;
  });
}
