import styled from 'styled-components';
import { Modal, ModalBody, Row } from 'reactstrap';

import { theme } from '../../styles';
import { Button } from '../../components/button/button';

export const StyledModal = styled(Modal)`
  .modal-content {
    position: fixed !important;
    left: ${(({ posX }) => posX)}px !important;
    top: ${(({ posY }) => posY)}px !important;
    max-width: ${({ xl }) => xl ? "744px" : "500px"};
  }

  font-weight: 500;
  color: #1a2240;
  label {
    color: #464f60;
  }
  .modal-content {
    border-radius: 12px;
  }

  max-height: 90hv;
  overflow-y: auto;
`;

export const StyledAddAttribute = styled(Button)`
  width: 24px;
  height: 24px;
`;

export const StyledModalBody = styled(ModalBody)`
  padding: 0.5rem 1rem 1rem 1rem;
  background-color: ${theme.colors.grey[0]};
`;

export const StyledDeleteButton = styled(Button)`
  width: 32px;
`;

export const StyledFileInput = styled.label`
  margin-bottom: 0;
  cursor: pointer;
  padding: 0;
  color: ${theme.colors.primary[600]} !important;
`;

export const StyledRow = styled(Row)`
  background-color: ${theme.colors.grey[50]};
  color: ${theme.colors.grey[700]};
  font-size: ${theme.fontSize.small};
  font-weight: 500;
  border-radius: 4px;
`;

export const StyledFile = styled.input`
  all: unset;
  flex: 1;
  padding: 2px;
  background: url(${({ icon }) => icon}) no-repeat scroll 8px 2px;
  padding-left: 28px;
`;

export const StyledTitleAttributes = styled.p`
  font-size: 16px;
`;

export const ActiveScrollbarY = styled.div`
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: ${({ maxHeight }) => maxHeight};
  scrollbar-width: ${({ scrollbarWidth }) => scrollbarWidth || 'auto'};
  
  &::-webkit-scrollbar {
    width: ${({ scrollbarWidth }) => scrollbarWidth || 'auto'};
  }
`;

export const RequiredStar = styled.span`
  margin: 0;
  color: ${theme.colors.primary["500"]};
`;

export const RequiredStarText = styled.span`
  margin: 0;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.medium};
`;

export const CustomSwitch = styled.div`
  width: 34px;
  height: 20px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  padding-left: ${({ active }) => active ? "15px" : "2px"};
  transition: all 0.2s;
  background-color: ${({ active }) => active ? theme.colors.primary["500"] : theme.colors.grey["300"]};
`;

export const CustomSwitchRound = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 13px;
  transition: all 0.2s;
  background-color: white;
`;

export const StyledListingWrapper = styled.div`
  background-color: white;
  width: 100%;
`;

export const StyledWrappedRow = styled.div`
  max-height: 50vh;
  overflow-y: scroll;
  width: 100%;
`;