import qs from 'qs';

export function getDirectoryPath(path) {
  const slicedPath = path.slice(0, 1) === '/' ? path.slice(1) : path;
  return slicedPath.substring(0, slicedPath.lastIndexOf('/') + 1);
}

export function removeMeta(path) {
  const slicedPath = path.slice(0, 1) === '/' ? path.slice(1) : path;
  return slicedPath.split('_meta/')[0];
}

export function getLevelsCount(path) {
  return path.split('/').length - 1;
}

export function getLevelUpPath(path) {
  if (path === '') return null;
  return '/' + path.substring(0, path.slice(0, -1).lastIndexOf('/') + 1);
}

export const isInFileProviderMode = () => {
  return Boolean(
    qs.parse(window.location.search, { ignoreQueryPrefix: true }).fileProvider
  );
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  }
  catch (e) {
    return true;
  }
};
