import { Map } from 'immutable';

import { SET_CREATE_FILE_MODAL } from '../../ibis-ui/actions/index';

const defaultState = Map({ createFileModal: { open: false, initFiles: [] } });

export const uiReducer = (state = defaultState, action) => {
  switch (action.type) {

  case SET_CREATE_FILE_MODAL:
    return state.setIn(
      [ "createFileModal", "open" ],
      action.payload.open
    ).setIn(
      [ "createFileModal", "initFiles" ],
      action.payload.initFiles ? [ ...state.getIn([ "createFileModal", "initFiles" ]), ...action.payload.initFiles ] : []
    );

  default:
    return state;
  }
};
