import styled, { css, keyframes } from 'styled-components';

import { theme } from '../../styles';

const slideDown = keyframes`
  from {
    height: 0px;
  }
  to {
    height: 20%;
  }
`;

export const AnimateSlideDown = styled.div`
  display: flex;
  width: calc(100% - 20px);
  gap: 36px;
  margin-left: 20px;
  /* animation: ${slideDown} 1s ease forwards; */
`;

export const TooltipContainer = styled.div`
  background-color: ${theme.colors.grey["900"]};
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TooltipText = styled.p`
  color: #7BCCD5;
  margin: 0;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
  ${({ isLabel }) => isLabel && css`
    height: 180px;
  `};
`;