import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Row } from 'reactstrap';

import Loading from '../../main/components/loading';
import NodeContainer from './nodeContainer';
import { useIBIS } from '../hooks/useIbis';
import { viewsLabels } from '../components/hints/views';
import { parseHints } from '../utils/hints';
import { __env } from '../../envloader';

export const getDefaultView = (node, pathname) => {
  const name = pathname.split("/").slice(-1)[0]
    ? pathname.split("/").slice(-1)[0]
    : pathname.split("/").slice(-2)[0];

  const isDirectory = pathname.lastIndexOf("/") === pathname.length - 1;
  const defaultView = isDirectory
    ? viewsLabels.LISTING
    : node?.hints?.schema?.json
      ? viewsLabels.FORM
      : node?.contentType === "application/json"
        ? viewsLabels.JSON
        : node?.contentType === "text/markdown"
          ? viewsLabels.MARKDOWN
          : node?.contentType === "text/html" &&
    name.toLowerCase() === "results.html"
            ? viewsLabels.EMBED
            : node?.contentType?.startsWith("text/") ||
      node?.contentType === "application/yaml" ||
      node?.contentType === "application/x-yaml"
              ? viewsLabels.TEXT
              : node?.contentType?.startsWith("image/")
                ? viewsLabels.IMAGE
                : viewsLabels.DOWNLOAD;

  return defaultView;
};

const MainDisplay = ({ hashPathname }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const IBIS = useIBIS(
    hashPathname ? hashPathname === pathname : true,
    undefined,
    hashPathname ? true : false
  );

  const notFoundMessage = pathname.endsWith("/")
    ? t("ibis-ui:directory_not_found")
    : t("ibis-ui:does_not_exist");
  const currentNodeReadMe = useIBIS(false, pathname + "README.md");

  const defaultView = getDefaultView(IBIS.node, pathname);

  const views = useMemo(() => {
    const viewsArray = parseHints(IBIS.node?.hints?.views).filter(
      (view) => view.type
    );

    return viewsArray.length > 0
      ? viewsArray
      : [
        {
          priority: 0,
          label: t("ibis-ui:" + defaultView),
          type: defaultView,
        },
      ];
  }, [ IBIS.node?.hints?.views, defaultView, t ]);
  if (
    (!IBIS.node && IBIS.isLoading) ||
    (IBIS.node?.items?.find(
      (node) => node.name.toLowerCase() === "readme.md"
    ) &&
      currentNodeReadMe.isLoading)
  ) {
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <div>
          <Row className="justify-content-center">
            <Loading />
          </Row>
          <h6>{t("ibis-ui:loading")}</h6>
        </div>
      </Row>
    );
  }

  if (IBIS.error)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>
          {IBIS.error.response?.status === 403
            ? t("ibis-ui:forbidden")
            : IBIS.error.response?.status === 404
              ? notFoundMessage
              : t("ibis-ui:backend_error")}
        </h6>
      </Row>
    );

  if (!IBIS.node)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>{notFoundMessage}</h6>
      </Row>
    );

  if (IBIS.node.size > __env.LARGE_FILE_SIZE)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>{t("ibis-ui:file_too_large")}</h6>
      </Row>
    );

  return <NodeContainer views={views} />;
};

MainDisplay.propTypes = {
  hashPathname: PropTypes.string,
};

export default MainDisplay;
