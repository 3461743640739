import React from 'react';
import { useLocation, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  StyledDropdownIcon,
  StyledDropdownButton,
} from '../dropdown/dropdown.styled';
import { Button } from './button';
import { ReactComponent as EditIcon } from '../../../assets/svg/icons/edit.svg';

const Edit = ({ name = "" }) => {
  const { t } = useTranslation();

  const { pathname, search } = useLocation();
  const history = useHistory();
  const queryParams = Object.fromEntries(new URLSearchParams(search));
  const isEditing = queryParams.edit === "true";

  const manageEditing = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isEditing)
      history.push(
        `${pathname}${name}?${new URLSearchParams({
          ...queryParams,
          edit: false,
        }).toString()}`
      );
    else
      history.push(
        `${pathname}${name}?${new URLSearchParams({
          ...queryParams,
          edit: true,
        }).toString()}`
      );
  };

  return name.length === 0 ? (
    <Button
      type={isEditing ? "destructive" : "secondary"}
      svgProperty={"fill"}
      icon={<EditIcon />}
      onClick={manageEditing}
      className="mr-3"
    >
      {isEditing ? t("ibis-ui:cancel") : t("ibis-ui:edit")}
    </Button>
  ) : (
    <StyledDropdownButton className="px-2" onClick={manageEditing}>
      <StyledDropdownIcon className="pr-1">
        <EditIcon className="align-self-center" />
      </StyledDropdownIcon>
      {t("ibis-ui:edit")}
    </StyledDropdownButton>
  );
};

Edit.propTypes = {
  name: PropTypes.string,
};

export default Edit;
