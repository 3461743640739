import { combineReducers } from 'redux';

import { LOG_IN_SUCCESS } from '../../oidc-middleware/actions/login';
import { nodeReducer } from './nodes';
import { mappingReducer } from './mapping';
import { cacheReducer } from './cache';
import { uiReducer } from './ui';

const combinedIBISReducers = combineReducers({
  nodes: nodeReducer,
  mapping: mappingReducer,
  cache: cacheReducer,
  ui: uiReducer
});

export const IBISReducers = (state, action) => {
  if (action.type === LOG_IN_SUCCESS) {
    state = undefined;
  }

  return combinedIBISReducers(state, action);
};