export const COLORS = [
  '#a6e1e7', 
  '#7bccd5', 
  '#32b4c3', 
  '#0097a8', 
  '#007785', 
  '#00606b', 
  '#004e57', 
  '#003c42', 
  '#9AE9D7', 
  '#6CD9C0', 
  '#46C2A5', 
  '#0EAB87', 
  '#039473', 
  '#15755F', 
  '#0F614E', 
  '#0B4538', 
  '#D5DBE5', 
  '#BCC2CE', 
  '#A1A9B8', 
  '#868FA0', 
  '#687182', 
  '#5A6376', 
  '#464F60', 
  '#333B4A', 
  '#a6d6e7', 
  '#7bbdd5', 
  '#329cc3', 
  '#007ba8', 
  '#006185', 
  '#004f6b', 
  '#004057', 
  '#003142'
];                                                   