import React from 'react';
import PropTypes from 'prop-types';
import Editor from '@monaco-editor/react';


const Text = ({ content, isEditing, editorRef }) => {
  return (
    <Editor
      onMount={(editor) => {
        editorRef.current = editor;
      }}
      value={content}
      defaultLanguage="plaintext"
      options={{
        readOnly: !isEditing,
        minimap: {
          enabled: isEditing,
        },
        scrollBeyondLastLine: isEditing,
      }}
    />
  );
};

Text.propTypes = {
  content: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  editorRef: PropTypes.object.isRequired,
};

export default Text;
