import _ from 'lodash';
import * as jexl from 'jexl';

import * as notify from '../../../main/utils/notify';
import i18n from '../../../main/utils/i18nConfigProvider';

/**
 * Calculate new state for form based on UI Schema field conditions and current form data
 *
 * @param originalSchema - Original full schema containing all possible fields
 * @param originalUISchema - Original full UI Schema containing all possible fields
 * @param schema - Current schema
 * @param uiSchema - Current UI schema
 * @param formData - Current form data
 * @return {object} - Object containing new schema, uiSchema, and formData
 */
export const processForm = (originalSchema, originalUISchema, schema, uiSchema, formData) => {
  const conditionalFields = _.pickBy(uiSchema, (field) => field.hasOwnProperty('visible'));
  if (_.isEmpty(conditionalFields)) {
    return {
      schema,
      uiSchema,
      formData
    };
  }

  let newSchema = _.assign({}, schema);
  let newUISchema = _.assign({}, uiSchema);
  let newFormData = _.assign({}, formData);

  _.each(conditionalFields, (dependantSchema, dependant) => {
    const shouldBeVisible = evalCondition(dependantSchema.visible, formData);
    if (shouldBeVisible) {
      newSchema.properties[dependant] = originalSchema.properties[dependant];
    }
    else {
      newSchema.properties = _.omit(newSchema.properties, [ dependant ]);
      newFormData = _.omit(newFormData, [ dependant ]);
    }
  });

  // Update UI Schema UI order
  // react-jsonschema-form cannot handle extra properties found in UI order
  if (originalUISchema['ui:order'])
    newUISchema['ui:order'] = _.intersection(
      originalUISchema['ui:order'],
      _.keys(newSchema.properties)
    );

  // Update Schema required fields
  if (originalSchema.hasOwnProperty('required')) {
    newSchema.required = _.intersection(
      originalSchema.required,
      _.keys(newSchema.properties)
    );
  }

  return {
    schema: newSchema,
    uiSchema: newUISchema,
    formData: newFormData
  };
};

export const validateForm = (uiSchema, formData, errors) => {
  const validatedFields = _.pickBy(uiSchema, field => field.hasOwnProperty('valid'));
  if (_.isEmpty(validatedFields)) {
    return errors;
  }
  _.each(validatedFields, (dependantSchema, dependant) => {
    if (errors[dependant] && !evalCondition(dependantSchema.valid, formData)) {
      errors[dependant].addError(dependantSchema.notValidWarning);
    }
  });
  return errors;
};

const evalCondition = (condition, formData) => {
  try {
    return jexl.evalSync(condition, formData);
  }
  catch (error) {
    notify.error(i18n.t('common:schema_syntax_error_title'), i18n.t('common:schema_syntax_error_message', {
      condition: condition,
      error: error.message
    }));
  }
};