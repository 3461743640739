import React from 'react';
import PropTypes from 'prop-types';

import { StyledNodesGrid } from './nodesGrid.styled';
import { NodesListTile } from './nodesGridTile';
import NodeContainer from '../../nodeContainer/nodeContainer';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';

export const NodesGrid = ({ resources, startPath }) => {
  const { lastElementRef } = useInfiniteScroll();

  const renderGrid = resources.map((node, index) => (
    <NodeContainer
      startPath={startPath}
      key={node.name + node.type}
      type={node.type}
      name={node.name}
      target={node.target}
    >
      <NodesListTile
        innerRef={index === resources.length - 1 ? lastElementRef : null}
        {...node}
      />
    </NodeContainer>
  ));

  return (
    <div className="p-3">
      <StyledNodesGrid>{renderGrid}</StyledNodesGrid>
    </div>
  );
};

NodesGrid.propTypes = {
  resources: PropTypes.array,
  startPath: PropTypes.string,
};

export default NodesGrid;
