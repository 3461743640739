import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '../../components/button/button';
import {
  StyledModalBody,
  StyledFileInput,
  ActiveScrollbarY,
} from './nodeCreator.styled';
import useIBIS from '../../hooks/useIbis';
import FileField from '../../components/fileField/fileField';
import UploadProgress from '../../components/fileField/uploadProgress';
import Dropzone from '../../components/dropzone/dropzone';
import actions from '../../actions';
import MovableModal from './movableModal';

export const fileCreatorModes = {
  add: "add",
  create: "create",
  modify: "modify",
};

const FileCreator = ({ mode }) => {
  const dispatch = useDispatch();
  const createFileModal = useSelector((state) =>
    state.IBIS.ui.get("createFileModal")
  );

  const modifyMode = mode === fileCreatorModes.modify;
  const addMode = mode === fileCreatorModes.add;
  const createMode = mode === fileCreatorModes.create;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const IBIS = useIBIS();

  const splitedPathname = pathname.split("/");
  const singleFileName = splitedPathname[splitedPathname.length - 1]
    ? splitedPathname[splitedPathname.length - 1]
    : splitedPathname[splitedPathname.length - 2];
  const pathnameForSingleFileCreation =
    pathname.slice(0, pathname.indexOf(singleFileName)) || "";

  const [ files, setFiles ] = useState(
    modifyMode && IBIS?.node
      ? [ new File([ IBIS?.node?.content ], singleFileName) ]
      : []
  );
  const [ filesProgress, setFilesProgress ] = useState([]);
  const [ filesCancel, setFilesCancel ] = useState([]);
  const [ empty, setEmpty ] = useState(false);

  useEffect(() => {
    if (!createFileModal.open) setFiles([]);
  }, [ createFileModal.open, singleFileName ]);

  useEffect(() => {
    if (createFileModal.initFiles.length > 0) {
      setFiles(createFileModal.initFiles);
      dispatch(
        actions.setCreateFileModal({
          open: true,
        })
      );
    }
    setEmpty(false);
  }, [ createFileModal.initFiles, dispatch ]);

  const handleFile = (e) => {
    if (e.target.files) {
      if (!IBIS.node || modifyMode) setFiles([ ...e.target.files ]);
      else setFiles((files) => [ ...files, ...e.target.files ]);
    }
  };

  const setProgress = (index, progress) => {
    setFilesProgress((prev) =>
      prev.map((prev, i) => (i === index ? progress : prev))
    );
  };

  const submit = async () => {
    if (files.length > 0) {
      setFilesProgress(new Array(files.length).fill(0));

      let directories = [];
      //this gets paths of all directories that need to exist in order to create all of the files
      if (
        files
          .map((file) => file.path ?? "/" + file.webkitRelativePath)
          .some((path) => path?.split("/").length > 1)
      )
        directories = [
          ...new Set(
            files
              .map((file) => {
                if (
                  (file.path ?? "/" + file.webkitRelativePath).split("/").length > 1
                ) {
                  let acc = "";
                  return (file.path ?? "/" + file.webkitRelativePath)
                    .split("/")
                    .slice(1, -1)
                    .map((subPath) => {
                      return (acc += "/" + subPath);
                    });
                }
                return null;
              })
              .filter((el) => el)
              .flat()
          ),
        ];

      await Promise.allSettled(
        directories.map((directory) => {
          const promises = IBIS.createDirectoryWithoutUpdating({
            path: pathname + directory.slice(1) + "/",
          });
          return promises;
        })
      );

      await Promise.allSettled(
        files.map((file, index) => {
          const controller = new AbortController();
          const dirPath = (file.path ?? "/" + file.webkitRelativePath)
            ?.split("/")
            .slice(1, -1)
            .join("/");
          const promise = modifyMode
            ? IBIS.patchFile({
              path: pathnameForSingleFileCreation + singleFileName,
              file: file,
              signal: controller.signal,
              onUploadProgress: (progressEvent) =>
                setProgress(
                  index,
                  Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                  )
                ),
            })
            : IBIS.createFile({
              path: !IBIS.node
                ? pathnameForSingleFileCreation + singleFileName
                : pathname +
                    ((file.path ?? "/" + file.webkitRelativePath) && dirPath
                      ? `${dirPath}/`
                      : "") +
                    file.name,
              file: file,
              signal: controller.signal,
              onUploadProgress: (progressEvent) =>
                setProgress(
                  index,
                  Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                  )
                ),
            });
          setFilesCancel((prev) => [ ...prev, controller ]);
          return promise;
        })
      );
      close();
    }
    else {
      if (modifyMode && empty)
        await IBIS.patchFile({
          path: pathnameForSingleFileCreation + singleFileName,
        });
      if (createMode && empty)
        await IBIS.createFile({
          path: pathnameForSingleFileCreation + singleFileName,
        });
      close();
    }
  };

  const close = () => {
    filesCancel.forEach((file) => file.abort());
    setFiles([]);
    setFilesProgress([]);
    setFilesCancel([]);
    dispatch(actions.setCreateFileModal({ open: false }));
  };

  const cancel = () => {
    filesCancel.forEach((controller) => controller.abort());
    close();
  };

  const condForDisableBTN =
    (files.length === 0 && !empty) ||
    files.some(
      (el) =>
        el.name === singleFileName &&
        IBIS?.node?.content &&
        el.size === IBIS?.node?.content.size
    );

  return (
    <MovableModal
      title={modifyMode
        ? t("ibis-ui:file_modal.modify.title")
        : addMode
          ? t("ibis-ui:file_modal.add.title")
          : t("ibis-ui:file_modal.create.title")}
      modal={createFileModal.open}
      closeModal={close}
    >
      <StyledModalBody>
        <Container className="p-2">
          <Row className="m-0 w-100">
            {modifyMode ? (
              <Col>
                <input
                  id="actual-btn"
                  hidden
                  type="file"
                  name="file"
                  onChange={handleFile}
                />
                <StyledFileInput
                  className="float-right"
                  htmlFor="actual-btn"
                  disabled={empty}
                >
                  + {t("ibis-ui:file_modal.select_file")}
                </StyledFileInput>
              </Col>
            ) : (
              <Col className="p-0">
                <Dropzone
                  small={true}
                  disabled={empty || filesProgress.length > 0}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col className="p-1 pt-3">
              {files.length > 0 ? (
                <label>{t("ibis-ui:selected_files")}:</label>
              ) : (
                (modifyMode || createMode) && (
                  <Row>
                    <label className="p-2 m-0">
                      {t("ibis-ui:file_modal.empty")}
                    </label>{" "}
                    <input
                      type="checkbox"
                      value={empty}
                      onChange={(e) => setEmpty(e.target.checked)}
                    />
                  </Row>
                )
              )}
            </Col>
          </Row>
          <ActiveScrollbarY maxHeight="300px" scrollbarWidth="8px">
            {files &&
              files.map((file, index) => {
                const fileName =
                  !IBIS.node || modifyMode ? singleFileName : file.name;

                return filesProgress.length > 0 ? (
                  <UploadProgress
                    progress={filesProgress[index]}
                    key={index}
                    name={fileName}
                    cancel={() => filesCancel[index].abort()}
                  />
                ) : (
                  <FileField
                    editFileModal={modifyMode}
                    createNewElFromURL={!IBIS.node}
                    key={index}
                    fileName={fileName}
                    file={file}
                    index={index}
                    setFiles={setFiles}
                  />
                );
              })}
          </ActiveScrollbarY>
        </Container>
      </StyledModalBody>
      <ModalFooter className="p-3">
        <Row className="m-0 float-right">
          <Button
            type="secondary"
            onClick={cancel}
            className={filesProgress.length === 0 ? "mr-4" : ""}
          >
            {t("ibis-ui:cancel")}
          </Button>
          {filesProgress.length === 0 && (
            <Button disabled={condForDisableBTN} onClick={submit}>
              {modifyMode
                ? t("ibis-ui:file_modal.modify.upload")
                : addMode
                  ? t("ibis-ui:file_modal.add.upload")
                  : t("ibis-ui:file_modal.create.upload")}
            </Button>
          )}
        </Row>
      </ModalFooter>
    </MovableModal>
  );
};

FileCreator.propTypes = {
  mode: PropTypes.string,
};

export default FileCreator;
