import React from 'react';
import PropTypes from 'prop-types';

import { FilterBadge, StyledBadge, Text } from './badge.styled';
import { icons } from '../../../assets/svg/icons/index.js';

export const Badge = ({ activeFilters, children, filterPanel, isAllUpperCase, withIcon, ...props }) => {
  
  if (filterPanel) {
    if (!children) return null;
    return (
      <FilterBadge activeFilters isAllUpperCase={isAllUpperCase} {...props}>
        <Text>{children}</Text>
        {withIcon && icons.crossIcon}
      </FilterBadge>
    );
  };

  return <StyledBadge {...props}>{children}</StyledBadge>;
};

Badge.propTypes = {
  activeFilters: PropTypes.bool,
  children: PropTypes.oneOfType([ PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.node ]),
  filterPanel: PropTypes.bool,
  isAllUpperCase: PropTypes.bool,
  withIcon: PropTypes.bool
};