import { SET_NODE, SET_LOADING, SET_ERROR, APPLY_PATCH, CLEAR_NODE, EXPAND_NODE } from '.';

export function setNode({ path, node, replace = true }) {
  return {
    type: SET_NODE,
    payload: { path, node, replace }
  };
}

export function expandNode({ path, node }) {
  return {
    type: EXPAND_NODE,
    payload: { path, node }
  };
}

export function setLoading({ path, replace = true }) {
  return {
    type: SET_LOADING,
    payload: { path, replace }
  };
}

export function setError({ path, error }) {
  return {
    type: SET_ERROR,
    payload: { path, error }
  };
}


export function applyPatch({ path, patch }) {
  return {
    type: APPLY_PATCH,
    payload: { path, patch }
  };
}

export function clearNode({ path }) {
  return {
    type: CLEAR_NODE,
    payload: { path }
  };
}
