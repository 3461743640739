import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import Callback from './callback';
import LogoutCallback from './logoutCallback';
import { loginCallbackPath, logoutCallbackPath } from '../utils/authUtils';
import NotFound from '../components/notFound';
import Loading from '../components/loading';

import { inIframe } from '../../ibis-ui/utils/urlHelpers';
import TopBar from '../../ibis-ui/pages/topbar/topbar';
import Main from '../../ibis-ui/app';
import '../../ibis-ui/styles/index.scss';


@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  })
)
export default class Root extends Component {
  renderContent = () => {

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading className="text-center mt-5" />;
    }

    if (!this.props.loginStore) {
      //User not logged in
      return (
        <Switch>
          <Route path={loginCallbackPath} exact component={Callback} />
          <Route path={logoutCallbackPath} exact component={LogoutCallback} />
          <Route path='/' component={Main} />
          <Route component={NotFound} />
        </Switch>
      );
    }
    else {
      //User logged in
      return (
        <Switch>
          <Route path={loginCallbackPath} exact render={() => <Redirect to='/'/>}/>
          <Route path={logoutCallbackPath} exact render={() => <Redirect to='/'/>}/>
          <Route path='/' component={Main} />
          <Route component={NotFound} />
        </Switch>
      );
    }
  };

  render() {
    return (
      <div>
        {!inIframe() && <TopBar />}
        <Container fluid='true'>
          <div className="main-content">
            {this.renderContent()}
          </div>
        </Container>
      </div>
    );
  }
}

Root.propTypes = {
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
};
