import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'reactstrap';

import Form from '../../../../main/components/forms/Form';

const RSJF = ({ onSubmit, submitRef, node, isEditing }) => {
  return (
    <Container fluid className="h-100">
      <Form
        submitRef={submitRef}
        formData={node.content}
        disabled={!isEditing}
        schema={node.hints.schema.json}
        uiSchema={node.hints.schema.ui}
        onSubmit={onSubmit}
      ></Form>
    </Container>
  );
};

RSJF.propTypes = {
  node: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
};

export default RSJF;
