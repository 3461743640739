import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

import { StyledRow } from './fileField.styled';
import { StyledProgressBar, StyledCancelButton } from './uploadProgress.styled';
import { ReactComponent as Cross } from '../../../assets/svg/icons/cross.svg';

const UploadProgress = ({ name, progress, cancel }) => {

  return (
    <StyledRow className="mt-2 position-relative">
      <StyledProgressBar progress={progress} />
      <Col className="w-100 pl-2">
        {name} (uploading {progress} %)
      </Col>
      <StyledCancelButton
        onClick={cancel}
        className="mr-1 zindex-tooltip"
        type="menu"
        icon={<Cross />}
      ></StyledCancelButton>
    </StyledRow>
  );
};

UploadProgress.propTypes = {
  name: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default UploadProgress;
