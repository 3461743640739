import styled, { css, keyframes } from 'styled-components';

import { theme } from '../../styles';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const StyledBadge = styled.span`
  background-color: ${theme.colors.grey["50"]};
  color: ${theme.colors.grey["600"]};
  border-radius: 4px;
  padding: 1px 8px;
  font-size: ${theme.fontSize.small};
  text-transform: uppercase;
  svg{
    vertical-align: sub;
    margin-right: 6px;
    transform: scale(0.75);
  }
`;

export const FilterBadge = styled(StyledBadge)`
  padding: 0 6px;
  background-color: ${theme.colors.primary["0"]};
  color: ${theme.colors.primary["500"]};
  font-size: ${theme.fontSize.extraSmall};
  font-weight: ${theme.fontWeight.medium};
  line-height: 16px;
  letter-spacing: 0.3px;

  width: max-content;
  display: flex;
  gap: 4px;

  ${({ activeFilters }) => activeFilters && css`
    color: ${theme.colors.primary["500"]};
    font-size: ${theme.fontSize.small};
    line-height: 18px;
    letter-spacing: 0.36px;
    padding: 3px 8px;
    cursor: pointer;
  `};

  ${({ isAllUpperCase }) => isAllUpperCase && css`
    color: ${theme.colors.primary["700"]};
  `};

  svg {
    stroke: ${theme.colors.primary["500"]};
    width: 18px !important;
    height: 18px !important;
    margin: 0;
  }
`;

export const Text = styled.div`
  max-width: 200px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  animation: ${fadeIn} 0.5s ease-in-out forwards;
`;