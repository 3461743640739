import React from 'react';
import PropTypes from 'prop-types';
import { StyledIframe } from './displayFile.styled';
import { __env } from '../../../../envloader';

const Frame = ({
  view
}) => {
  const location = __env.THEME.assets.frames[view.asset];

  return <StyledIframe
    src={`${new URL(__env.THEME_ASSETS_BASE_URL)}${location}?format=raw`}
    title="HTML"
    sandbox="allow-same-origin allow-scripts"
    contentsecuritypolicy="default-src"
  />;
};

Frame.propTypes = {
  view: PropTypes.object.isRequired,
};

export default Frame;
