import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  StyledDropdownIcon,
  StyledDropdownButton,
} from '../dropdown/dropdown.styled';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/icons/delete.svg';
import { Button } from './button';
import { useIBIS } from '../../hooks/useIbis';
import { useLocation } from 'react-router';

const Delete = ({ name }) => {
  const { pathname } = useLocation();
  const IBIS = useIBIS();
  const { t } = useTranslation();
  const [ modal, setModal ] = useState(false);

  const deleteResourceName = (
    name ??
    (pathname.slice(-1) === "/"
      ? pathname.split("/").slice(-2)[0]
      : pathname.split("/").slice(-1)[0])
  ).replaceAll("/", "");

  const toggleModal = () => setModal(!modal);

  const openModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setModal(true);
  };

  const submit = () => {
    IBIS.delete({ path: pathname + (name ? name : "") });
    toggleModal();
  };

  return (
    <React.Fragment>
      {modal ? (
        <Modal size="sm" isOpen={true} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {t("ibis-ui:delete_confirm", { name: deleteResourceName })}
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row className="m-0">
                <Col>
                  <Button
                    className="w-100"
                    type="secondary"
                    onClick={toggleModal}
                  >
                    {t("ibis-ui:cancel")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    icon={<DeleteIcon />}
                    className="w-100"
                    onClick={submit}
                  >
                    {t("ibis-ui:delete")}
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      ) : null}
      {name ? (
        <StyledDropdownButton
          className="px-2"
          type="destructive"
          onClick={openModal}
        >
          <StyledDropdownIcon className="pr-1">
            <DeleteIcon className="align-self-center" />
          </StyledDropdownIcon>
          {t("ibis-ui:delete")}
        </StyledDropdownButton>
      ) : IBIS.node ? (
        <Button
          icon={<DeleteIcon />}
          type="destructive"
          className="ml-2"
          onClick={openModal}
        >
          {t("ibis-ui:delete")}
        </Button>
      ) : null}
    </React.Fragment>
  );
};

Delete.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
};

export default Delete;
