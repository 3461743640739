import styled, { css, keyframes } from 'styled-components';

import { theme } from '../../../../styles';
import { Link } from 'react-router-dom';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ButtonContainer = styled.div`
  z-index: 2;
  position: absolute;
  right: 0;
  display: flex;
  padding: 8px 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${theme.colors.white};
  border-radius: 0px 0px 6px 6px;
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(17, 24, 38, 0.10), 0px 0px 0px 1px rgba(152, 161, 178, 0.10);
`;

export const ButtonAction = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: none; 
  border-radius: 6px;
  background: ${theme.colors.grey["0"]};
  cursor: pointer;

  &:hover, &:active {
    path[fill] {
      fill: ${theme.colors.grey["500"]};
    }
  }
`;
export const CustomLink = styled(Link)`
  text-decoration: none;
  ${({ isCursor }) => `cursor: ${isCursor ? 'pointer' : 'auto'};`};
`;

export const CustomButton = styled.button`
  display: flex; 
  justify-content: center;
  gap: 8px;
  padding: 0;
  border: none;
  background: ${theme.colors.white};
  color: ${theme.colors.primary["500"]};
  
  &:hover {
    background: ${theme.colors.white};
  }
  .icon {
    display: none;
    cursor: pointer;
  }

  &:hover .icon {
    display: inline-block;
    width: 19px;
    height: 19px;
  }
`;

export const ContainerInput = styled.div`
  position: relative;
`;

export const CustomInput = styled.input`
  position: relative;
  padding: 2px 45px 2px 12px;
  width: 320px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--White, #FFF);
  border: none;
  box-shadow: 0px 1px 1px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(70,79,96,0.16);
  z-index: 3;

  &:focus {
    outline: none;
    border: none;
  }

  ${({ error }) => error && css`
    box-shadow: 0px 0px 1px 2px rgba(210, 7, 36, 0.60), 0px 0px 0px 1px rgba(134, 143, 160, 0.32), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  `};
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
`;

export const ErrorMsg = styled.div`
  max-width: 250px;
  animation: ${fadeIn} 1s forwards;
  position: absolute;
  padding: 5px 0 0 0;
  color: ${theme.colors.danger};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
`;

export const NewSection = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: none;
  padding: 5px 8px;
  background: #E9EDF5;

  color: ${theme.colors.grey["700"]};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const StyledBreadCrumbs = styled.ul`
  height: 36px;
  max-height: 60px;
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0 0 4px 0;
`;