import qs from 'qs';
import { objectExists } from './utils';

import { __env } from '../../envloader';

export class Client {
  constructor({ tenantUrl, getSecret, axiosInstance }) {
    this.tenantUrl = tenantUrl;
    this.getSecret = getSecret;
    this._axiosInstance = axiosInstance;
  }

  createNode = ({
    node,
    path,
    onUploadProgress = null,
    method = "post",
    headers,
    signal,
  }) => {
    return this.sendRequest({
      method,
      path,
      data: node,
      onUploadProgress,
      headers,
      signal,
    });
  };

  deleteNode = ({ path }) => {
    return this.sendRequest({ method: "delete", path: path });
  };

  fetchDirectory = ({ path, query, fetchAll }) => {
    return new Promise((resolve, reject) => {
      this._fetchMore({
        currentResult: [],
        path: path,
        query: { ...query, format: "ui" },
        fetchAll: fetchAll,
        resolve: resolve,
        reject: reject,
      });
    });
  };

  fetchFile = async ({
    path,
    responseType,
    detectContentType,
    contentType,
  }) => {
    let finalContentType = contentType;
    let contentTypeHeaders = {};
    let contentData = {};

    if (detectContentType) {
      const { headers, data } = await this.fetchContentType({ path: path });
      contentTypeHeaders = headers;
      finalContentType = data.content_type ?? headers["content-type"];
      contentData = data;
    }

    if (detectContentType) {
      const isText = finalContentType?.startsWith("text/");
      const isImage = finalContentType?.startsWith("image/");
      if (
        contentData.size > __env.LARGE_FILE_SIZE ||
        (!isText && !isImage &&
          ![
            "application/json",
            "application/x-yaml",
            "application/yaml",
          ].includes(finalContentType))
      )
        return Promise.resolve({
          headers: contentTypeHeaders,
          data: {
            content: null,
            size: contentData.size,
            contentType: finalContentType,
            lastModified: contentData.modified,
          },
        });
    }

    let finalResponseType;
    if (finalContentType?.substr(0, finalContentType.indexOf("/")) === "image")
      finalResponseType = "blob";

    if (finalContentType?.substr(0, finalContentType.indexOf("/")) === "text")
      finalResponseType = "text";

    finalResponseType = responseType ?? finalResponseType;

    return this.sendRequest({
      method: "get",
      path: path,
      contentType: finalContentType,
      responseType: finalResponseType,
      __silentFor: [ { status: 404 }, { status: 403 } ],
    }).then(async (response) => {
      return {
        ...response,
        headers: response.headers,
        data: {
          ...response.data,
          ...contentData,
          content:
            finalResponseType === "text" && response.data instanceof Object
              ? JSON.stringify(response.data, null, 2)
              : response.data,
          size: contentData.size,
          contentType: finalContentType,
          lastModified: contentData.modified,
        },
      };
    });
  };

  getEmbedContent = ({ path }) => this.sendRequest({
    method: "get",
    path: path,
    query: {
      embed: "parent",
      redirect: "explicit",
    },
    __silentFor: [ { status: 404 }, { status: 403 } ],
  });

  fetchContentType = ({ path }) => {
    return this.sendRequest({
      query: { format: "ui" },
      method: "get",
      path: path,
      __silentFor: [ { status: 404 }, { status: 403 } ],
    }).then((response) => {
      return { headers: response.headers, data: response.data };
    });
  };

  _fetchMore = ({
    currentResult = [],
    path,
    query,
    fetchAll,
    resolve,
    reject,
  }) => {
    return this.sendRequest({
      method: "get",
      path,
      query,
      __silentFor: [ { status: 404 }, { status: 403 }, { status: 400, code: "INVALID_NODE_TYPE" } ],
    })
      .then((response) => {
        const items = currentResult.concat(response.data.items);
        if (!fetchAll) {
          //Load one page results
          query = response.data.more
            ? qs.parse(response.data.more, { ignoreQueryPrefix: true })
            : null;
          resolve({ ...response, data: { ...response.data, more: query } });
        }
        else {
          //Load all results
          if (response.data.more) {
            query = qs.parse(response.data.more, { ignoreQueryPrefix: true });
            this._fetchMore({
              currentResult: items,
              path,
              query,
              fetchAll: true,
              resolve,
              reject,
            });
          }
          else {
            resolve({ ...response, data: { ...response.data, items } });
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  };

  touch = (path) => {
    return this.sendRequest({
      method: "PUT",
      path
    });
  };

  sendRequest = ({
    method,
    path,
    data,
    query = {},
    responseType,
    onUploadProgress,
    __silentFor,
    signal,
    headers = {},
  }) => {
    const fullAddress =
      this.tenantUrl +
      (path[0] === "/" ? path.substr(1) : path) + '?' + qs.stringify(query);
   
    const finalHeader = { ...headers };
    const secret = this.getSecret();
    if (secret) {
      finalHeader["X-ibis-Secret"] = secret;
      finalHeader["Authorization"] = `secret ${secret}`;
    }

    return this._axiosInstance({
      ...(method && { method }),
      ...(fullAddress && { url: fullAddress }),
      ...(data && { data: data }),
      ...(objectExists(finalHeader) && { headers: finalHeader }),
      ...(responseType && { responseType }),
      ...(onUploadProgress && {
        onUploadProgress: onUploadProgress,
      }),
      ...(__silentFor && { __silentFor }),
      __useAuthorizationHeader: "onlyForLoggedIn",
      ...(signal && { signal }),
    })?.then((res) => {
      return res;
    });
  };
}
