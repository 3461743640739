import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { List } from 'immutable';
import { Col, Container, Row, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/button/button';
import { Input } from '../../components/input/input';
import {
  StyledAddAttribute,
  StyledModalBody,
  StyledDeleteButton,
  StyledFileInput,
  RequiredStar,
  RequiredStarText,
} from './nodeCreator.styled';
import useIBIS from '../../hooks/useIbis';
import { ReactComponent as Add } from '../../../assets/svg/icons/add.svg';
import { ReactComponent as Trash } from '../../../assets/svg/icons/trash.svg';
import actions from '../../actions';
import MovableModal from './movableModal';

export const directoryCreatorModes = {
  add: "add",
  create: "create",
  edit: "edit",
};

const DirectoryCreator = ({ modal, closeModal, mode }) => {
  const addMode = mode === directoryCreatorModes.add;

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const IBIS = useIBIS();
  const splitedPathname = pathname.split("/");

  const [ name, setName ] = useState(
    mode === "create"
      ? splitedPathname[splitedPathname.length - 1]
        ? splitedPathname[splitedPathname.length - 1]
        : splitedPathname[splitedPathname.length - 2]
      : ""
  );

  const pathnameForFolderFromURLCreation =
    pathname.slice(0, pathname.indexOf(name)) || "";
  const [ attributes, setAttributes ] = useState(List([]));

  const dispatch = useDispatch();

  const handleFile = (e) => {
    close();
    dispatch(
      actions.setCreateFileModal({
        open: true,
        initFiles: e.target.files,
      })
    );
  };

  const close = () => {
    setName(splitedPathname[splitedPathname.length - 1]);
    setAttributes(List([]));
    closeModal();
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleAttributeChange = (event, index) => {
    setAttributes(
      attributes.set(index, {
        ...attributes.get(index),
        [event.target.name]: event.target.value,
      })
    );
  };

  const remove = (index) => {
    setAttributes(attributes.delete(index));
  };

  const add = () => {
    setAttributes(attributes.push({ key: "", value: "" }));
  };

  const submit = () => {
    if (name.length > 0) {
      let parsedAttributes = {};
      attributes.toJS().forEach((x) => {
        if (x.key.length > 0 && x.value.length > 0)
          parsedAttributes[x.key] = x.value;
      });

      IBIS.createDirectory({
        path:
          (!IBIS.node
            ? pathnameForFolderFromURLCreation + name
            : pathname + name) + "/",
        attributes: parsedAttributes,
      }).finally(() => {
        close();
      });
    }
  };

  const createAttributesFields = () => {
    return (
      <>
        {attributes.map((_, index) => (
          <Row key={index} className={`d-flex ${index > 0 ? "pt-2" : ""}`}>
            <Col className="p-0 pr-2">
              <Input
                name="key"
                placeholder="key"
                value={attributes.get(index).key}
                onChange={(e) => handleAttributeChange(e, index)}
              />
            </Col>
            <Col className="p-0 pr-2">
              <Input
                name="value"
                placeholder="value"
                value={attributes.get(index).value}
                onChange={(e) => handleAttributeChange(e, index)}
              />
            </Col>
            <StyledDeleteButton
              icon={<Trash />}
              type="secondary"
              name="-"
              onClick={() => remove(index)}
            />
          </Row>
        ))}
      </>
    );
  };

  return (
    <MovableModal
      title={addMode ? t("ibis-ui:add_folder") : t("ibis-ui:create_folder")}
      modal={modal}
      closeModal={close}
    >
      <StyledModalBody className="p-3">
        <Container>
          {addMode && (
            <>
              <Row className="m-0 w-100">
                <Col>
                  <input
                    id="actual-btn"
                    type="file"
                    name="directory"
                    mozdirectory
                    msdirectory
                    odirectory
                    webkitdirectory="true"
                    directory
                    multiple
                    hidden
                    onChange={handleFile}
                  />
                  <StyledFileInput className="float-right" htmlFor="actual-btn">
                    + {t("ibis-ui:file_modal.select_directory")}
                  </StyledFileInput>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <label htmlFor="name">{t("ibis-ui:folder_name")}<RequiredStar>&nbsp;*</RequiredStar></label>
            <Input
              disabled={!IBIS.node}
              name="name"
              value={name}
              onChange={handleChange}
            />
          </Row>

          <Row className="py-3">
            <label className="m-0" htmlFor="attributes">
              {t("ibis-ui:attributes")}
            </label>
            <StyledAddAttribute
              name="attributes"
              className="ml-3"
              icon={<Add />}
              type="secondary"
              onClick={add}
            ></StyledAddAttribute>
          </Row>

          {createAttributesFields()}
        </Container>
      </StyledModalBody>
      <ModalFooter className="p-3">
        <Row className="m-0 w-100 d-flex justify-content-between">
          <Col className="p-0 d-flex align-items-center">
            <RequiredStar>*&nbsp;</RequiredStar>
            <RequiredStarText>{t('ibis-ui:required_fields')}</RequiredStarText>
          </Col>
          <Col className="p-0 d-flex justify-content-end">
            <Button type="secondary" onClick={close} className="mr-4">
              {t("ibis-ui:cancel")}
            </Button>
            <Button disabled={name.length === 0} onClick={submit}>
              {addMode ? t("ibis-ui:add_folder") : t("ibis-ui:create_folder")}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </MovableModal>
  );
};

DirectoryCreator.propTypes = {
  modal: PropTypes.bool,
  closeModal: PropTypes.func,
  mode: PropTypes.string,
};

export default DirectoryCreator;
