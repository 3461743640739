import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Row } from 'reactstrap';

import { useIBIS } from '../../../hooks/useIbis.js';
import Node from '../node.jsx';
import Loading from '../../../../main/components/loading.jsx';

const Header = ({ startPath, view }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const finalPathname = pathname + (startPath ?? "");
  const IBIS = useIBIS(true, finalPathname);

  if (IBIS.isLoading) {
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <div>
          <Row className="justify-content-center">
            <Loading />
          </Row>
          <h6>{t("ibis-ui:loading")}</h6>
        </div>
      </Row>
    );
  }

  if (!IBIS.node?.content) return null;

  return (
    <Node
      node={IBIS.node}
      fetchMore={IBIS.fetchMore}
      view={view}
      isEditing={false}
      startPath={startPath}
      saveFile={() => {}}
      getEmbedContent={IBIS.getEmbedContent}
      isLoading={IBIS.isLoading}
    />
  );
};

Header.propTypes = {
  startPath: PropTypes.string,
  view: PropTypes.string,
};

export default Header;
