import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

import {
  StyledNodesTileItem,
  StyledFileName,
  StyledIcon,
  StyledBody,
} from './nodesGrid.styled';
import ItemIcon from '../../../fileIcon/fileIcon';
import Attributes from '../../attributes/attributes';

export const NodesListTile = ({
  attributes,
  name,
  type,
  content_type,
  onClick,
  innerRef,
  to,
}) => {
  return (
    <StyledNodesTileItem onClick={onClick} to={to} tabIndex={0} ref={innerRef}>
      <StyledIcon>
        <ItemIcon content_type={content_type} type={type} />
      </StyledIcon>
      <StyledBody className="p-2">
        <Row className="m-0">
          <StyledFileName>{name}</StyledFileName>
        </Row>
        <Row className="m-0 my-auto">
          <Attributes attributes={attributes} isHeader={false} />
        </Row>
      </StyledBody>
    </StyledNodesTileItem>
  );
};

NodesListTile.propTypes = {
  attributes: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.number,
  user_content_last_modified: PropTypes.string,
  content_type: PropTypes.string,
  children: PropTypes.element,
  onClick: PropTypes.func,
  innerRef: PropTypes.func,
  to: PropTypes.string,
};

export default NodesListTile;
