import React from 'react';
import { Nav, Collapse } from 'reactstrap';
import { Route, Link } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';

import TopbarButtons from '../../components/hints/topbar';
import { ReactComponent as IBISLogo } from '../../../assets/svg/icons/logo.svg';
import Login from '@main/containers/login';
import { ReactComponent as Signet } from '../../../assets/svg/icons/signet.svg';
import { StyledNavbar } from './topbar.styled';
import { useIBIS } from '../../hooks/useIbis';
import { theme } from '../../styles/index';

const TopBar = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const disableTopbar = queryParams.get("topbar") === "false";

  const url = theme.mainLogo
    ? URL.createObjectURL(new Blob([ theme.mainLogo ], { type: "image/svg+xml" }))
    : null;

  const IBIS = useIBIS(false);
  if (
    disableTopbar ||
    IBIS.node?.hints?.topbar?.visibility === "hidden" ||
    IBIS.node?.hints?.topbar?.visibility === "collapsed"
  )
    return null;

  return (
    <StyledNavbar expand>
      <NavbarBrand
        className="h-100"
        tag={Link}
        to={"/" + window.location.search}
      >
        {url ? (
          <img src={url} alt="logo"></img>
        ) : (
          <>
            <Signet className="mr-2 h-100 w-auto" />
            <IBISLogo />
          </>
        )}
      </NavbarBrand>
      <TopbarButtons />
      <Collapse navbar>
        <Nav className="ml-auto d-flex h-100" navbar pills>
          <Route strict path="/" render={(props) => <Login />} />
        </Nav>
      </Collapse>
    </StyledNavbar>
  );
};

export default TopBar;
