import React from 'react';
import PropTypes from 'prop-types';
import Editor from '@monaco-editor/react';

const JSONComponent = ({ content, isEditing, editorRef }) => {
  return (
    <Editor
      onMount={(editor) => {
        editorRef.current = editor;
      }}
      defaultLanguage="json"
      value={JSON.stringify(content, null, 2)}
      options={{
        readOnly: !isEditing,
        minimap: {
          enabled: isEditing,
        },
        scrollBeyondLastLine: isEditing,
      }}
    />
  );
};

JSONComponent.propTypes = {
  content: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  editorRef: PropTypes.object.isRequired,
};

export default JSONComponent;
