import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, ModalFooter, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Form from '../../../main/components/forms/Form';
import { v4 as uuidv4 } from 'uuid';

import { directoryCreatorModes } from '../nodeCreator/directoryCreator';
import { Button } from '../../components/button/button';
import { Input } from '../../components/input/input';
import {
  StyledModalBody,
  StyledTitleAttributes,
  RequiredStar,
  RequiredStarText,
} from './nodeCreator.styled';
import useIBIS from '../../hooks/useIbis';
import MovableModal from './movableModal';

const DirectoryCreatorForm = ({ actionData, closeModal, modal, mode }) => {
  const addMode = mode === directoryCreatorModes.add;
  const editMode = mode === directoryCreatorModes.edit;

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const IBIS = useIBIS();
  const submitRef = useRef(null);
  const splitedPathname = pathname.split("/");

  const [ name, setName ] = useState("");

  const pathnameForFolderFromURLCreation =
    pathname.slice(0, pathname.indexOf(name)) || "";

  const { initial, schema } = actionData?.data?.form || {};

  const close = () => {
    setName(
      mode === directoryCreatorModes.edit
        ? splitedPathname[splitedPathname.length - 2]
        : ""
    );
    closeModal();
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const submit = (e) => {
    let path = typeof actionData?.path?.[0] === 'string' ? actionData?.path?.[0] : '';
    let attributes = {};
    if (e)
      for (const [ key, value ] of Object.entries(e.formData)) {
        if (value) {
          attributes[key] = value;
        }
      }

    mode === directoryCreatorModes.edit
      ? IBIS.patchDirectory({
        path: pathname,
        attributes: { ...attributes, ...actionData?.data?.fixed },
      }).finally(() => {
        close();
      })
      : IBIS.createDirectory({
        path:
            (!IBIS.node
              ? pathnameForFolderFromURLCreation + path + name
              : pathname + path + name) + "/",
        attributes: { ...attributes, ...actionData?.data?.fixed },
      }).finally(() => {
        close();
      });
  };


  useEffect(() => {
    const splitedPathname = pathname.split("/");

    if (mode === directoryCreatorModes.edit) {
      setName(splitedPathname[splitedPathname.length - 2]);
    } 
    else if (actionData?.path?.[0]?.random) {
      setName(uuidv4());
    } 
    else {
      setName("");
    }
  }, [ pathname, mode, actionData ]);

  const createForm = () => {
    return (
      <div className="mx-n3">
        <StyledTitleAttributes className="mt-4 mb-2 small">
          {t("ibis-ui:attributes")}
        </StyledTitleAttributes>
        <Form
          submitRef={submitRef}
          formData={initial}
          disabled={false}
          schema={schema.json}
          onSubmit={submit}
        />
      </div>
    );
  };

  return (
    <MovableModal
      title={actionData?.title}
      modal={modal}
      closeModal={close}
    >
      <StyledModalBody className="p-3">
        <Container>
          {!editMode && (
            <Row className="mb-3">
              <label htmlFor="name">{t("ibis-ui:folder_name")}<RequiredStar>&nbsp;*</RequiredStar></label>
              <Input
                disabled={mode === directoryCreatorModes.edit ?? !IBIS.node}
                name="name"
                value={name}
                onChange={handleChange}
                readOnly={actionData?.path?.[0]?.random}
              />
            </Row>
          )}

          {actionData?.data?.form && createForm()}
        </Container>
      </StyledModalBody>
      <ModalFooter className="p-3">
        <Row className="m-0 w-100 d-flex justify-content-between">
          <Col className="p-0 d-flex align-items-center">
            <RequiredStar>*&nbsp;</RequiredStar>
            <RequiredStarText>{t('ibis-ui:required_fields')}</RequiredStarText>
          </Col>
          <Col className="p-0 d-flex justify-content-end">
            <Button type="secondary" onClick={close} className="mr-4">
              {t("ibis-ui:cancel")}
            </Button>
            <Button
              onClick={() => {
                if (submitRef.current) submitRef.current.click();
                else submit();
              }}
            >
              {editMode
                ? t("ibis-ui:save")
                : addMode
                  ? t("ibis-ui:add_folder")
                  : t("ibis-ui:create_folder")}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </MovableModal>
  );
};

DirectoryCreatorForm.propTypes = {
  actionData: PropTypes.object,
  modal: PropTypes.bool,
  closeModal: PropTypes.func,
  mode: PropTypes.string,
};

export default DirectoryCreatorForm;
