import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CustomNavs = ({ navs: { links }, onNavChange }) => (
  <Nav tabs>
    {links.map(({ nav, name, icon, isActive }, index) =>
      <NavItem key={index}>
        <NavLink
          className={classnames({ active: isActive })}
          onClick={() => onNavChange(nav)}
        >
          {icon && <span className={icon} aria-hidden='true' />}
          &nbsp;
          {name || nav}
        </NavLink>
      </NavItem>
    )}
  </Nav>
);

CustomNavs.propTypes = {
  navs: PropTypes.object,
  onNavChange: PropTypes.func
};

export default CustomNavs;