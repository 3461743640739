import React from 'react';
import axios from '@axios';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';

import {
  StyledDropdownIcon,
  StyledDropdownButton,
} from '../dropdown/dropdown.styled';
import { Button } from './button';
import { __env } from '../../../envloader';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/icons/download.svg';
import { fetchFile } from '../../services';

const Download = ({ name = "", size }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const download = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    size > __env.LARGE_FILE_SIZE ? downloadLargeFile() : downloadFile();
  };

  const downloadLargeFile = async () => {
    const response = await axios.head(
      `${__env.IBIS_TENANT_URL}${pathname.substr(1)}${name}?token-for-download=`
    );
    const token = response.headers["ibis-token-query"];
    const link = document.createElement("a");

    link.href = `${__env.IBIS_TENANT_URL}${pathname.substr(
      1
    )}${name}?${token}&format=raw`;
    link.click();
  };

  const downloadFile = () => {
    fetchFile({ path: pathname + name, responseType: "blob" }).then((file) => {
      FileSaver.saveAs(
        file.data.content,
        name.length > 0  ? name : pathname.split("/").slice(-1)[0]
      );
    });
  };

  if (name)
    return (
      <StyledDropdownButton className="px-2" onClick={download}>
        <StyledDropdownIcon className="pr-1">
          <DownloadIcon className="align-self-center" />
        </StyledDropdownIcon>
        {t("ibis-ui:download")}
      </StyledDropdownButton>
    );

  return (
    <Button icon={<DownloadIcon />} className="ml-2" onClick={download}>
      {t("ibis-ui:download")}
    </Button>
  );
};

export default Download;

Download.propTypes = {
  path: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
};
