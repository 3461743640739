import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../button/button';
import { ReactComponent as More } from '../../../assets/svg/icons/more.svg';
import Dropdown from './dropdown';

const DropdownButton = ({ children }) => {
  const node = useRef();
  const [ dropdown, setDropdown ] = useState(false);

  return (
    <div ref={node} className="position-relative">
      <Button
        type="secondary"
        icon={<More />}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setDropdown(!dropdown);
        }}
      />
      {dropdown && (
        <Dropdown
          triggerRef={node}
          changeDropdownState={setDropdown}
          className="mt-2 p-2"
        >
          {children.map((child, index) =>
            React.cloneElement(child, {
              key: index,
              onClick: () => {
                setDropdown(false);
                child.props.onClick();
              },
            })
          )}
        </Dropdown>
      )}
    </div>
  );
};

DropdownButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DropdownButton;
