import styled, { css, keyframes } from 'styled-components';

import { theme } from '../../styles';

const rotateAnimation = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const FilterContainer = styled.div`
  background: rgba(247, 249, 252, 0.80);
  border-left: 1px solid ${theme.colors.grey["100"]};
  border-right: 1px solid ${theme.colors.grey["100"]};
  ${({ wide }) => wide ? 'width: 100%;' : 'max-width: 368px;'}

  ${({ modal }) => modal && css`
    padding: 4px;
    border: 1px solid ${theme.colors.grey["100"]};
    border-radius: 6px;
    background-color: ${theme.colors.white};
    height: 50%;
  `}
`;

export const Scroll = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: .75rem;
  width: 100%;
  
  ${({ modal }) => modal && css`
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    scrollbar-width: 10px;
    padding: 1rem;
    ::-webkit-scrollbar {
      width: 10px;
    }

    @media (max-width: 1350px) {
      max-height: 250px;
    }
  `}
`;

export const SearchContainer = styled.div`
  position: relative;

  svg {
    position: absolute;
    left: 12px;
    top: 50%;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
    pointer-events: none;
  }

`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 6px 10px 6px 36px;
  border: none;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgba(134, 143, 160, 0.40), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  
  &::placeholder {
    color: #A1A9B8;
    font-size: ${theme.fontSize.medium};
    font-weight: ${theme.fontSize.normal};
    line-height: 20px;
  }

  &:focus {
    outline: none;
  }
`;

export const AmountOfFilters = styled.div`
  color: ${theme.colors.grey["500"]};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontSize.medium};
  line-height: 18px;
  letter-spacing: 0.36px;
  text-transform: uppercase;

  height: 32px;
  margin-top: .625rem;
`;

export const Refresh = styled.div`
  cursor: pointer;
  height: 100%;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isAnimation }) => isAnimation && css`
    img {
      animation: ${rotateAnimation} 1s ease-out;
    }
  `}
`;

export const BadgeContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;

export const FilterType = styled.div`
  color: ${theme.colors.grey[900]};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
`;

export const Filter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const FilterName = styled.div`
  color: ${theme.colors.grey["700"]};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 240px;
`;

export const FilterAnimation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out, max-height ${({ expanded }) => (expanded ? '1s ease-in-out' : '0.5s ease-out')};
  opacity: ${({ expanded }) => (expanded ? '1' : '0')};
  max-height: ${({ expanded }) => (expanded ? '1000px' : '0')};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  transition: all 0.5s;
`;

export const ShowMore = styled.button`
  transition: all 0.5s;
  border: none;
  background: transparent;
  color: ${theme.colors.primary["500"]};
  text-align: center;
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  line-height: 18px;
  letter-spacing: 0.36px;
`;