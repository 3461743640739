import React, { useState } from 'react';
import { ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

import { StyledModal } from './nodeCreator.styled';

const MovableModal = ({ children, closeModal, modal, title, xl }) => {
  const [ offsets, setOffsets ] = useState({ x: "50%", y: "50%" });

  const close = () => {
    setOffsets({ x: "50%", y: "50%" });
    closeModal();
  };

  const handleMouseDown = (e) => {
    const modalElement = e.target.closest(".modal-content");
    const rect = modalElement.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    const handleMouseMove = (moveEvent) => {
      const left = moveEvent.clientX - offsetX;
      const top = moveEvent.clientY - offsetY;

      setOffsets({ x: left, y: top });
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <StyledModal
      isOpen={modal}
      toggle={close}
      posX={offsets.x}
      posY={offsets.y}
      centered
      xl={xl}
    >
      <ModalHeader
        onMouseDown={handleMouseDown}
        className="p-3"
        style={{ cursor: "move" }}
      >
        <h6 className="m-0">{title}</h6>
      </ModalHeader>
      {children}
    </StyledModal>
  );
};

MovableModal.propTypes = {
  children: PropTypes.element,
  closeModal: PropTypes.func,
  modal: PropTypes.bool,
  title: PropTypes.string,
  xl: PropTypes.bool
};

export default MovableModal;
