import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import {
  StyledDropzone,
  StyledNoFilesText,
  StyledAddFiles,
  StyledLightText,
} from './dropzone.styled';

import { ReactComponent as DropzoneLogo } from '../../../assets/svg/icons/dropzone.svg';
import actions from '../../actions';

export const Dropzone = ({ small, disabled = false, onDrop }) => {
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: disabled,
  });

  return (
    <>
      <StyledDropzone
        disabled={disabled}
        {...getRootProps()}
        className="w-100 h-100 py-5 px-3 d-flex flex-column justify-content-center"
      >
        <input {...getInputProps()} disabled={disabled} />
        {!small && (
          <>
            <DropzoneLogo className="w-100" />
            <div className="w-100 d-flex justify-content-center p-2">
              <StyledNoFilesText>{t("ibis-ui:no_files")}</StyledNoFilesText>
            </div>
          </>
        )}
        <div className="w-100 d-flex justify-content-center">
          <StyledAddFiles disabled={disabled}>
            + {t("ibis-ui:select_files")}
          </StyledAddFiles>
          <StyledLightText>{t("ibis-ui:drag_file")}</StyledLightText>
        </div>
      </StyledDropzone>
    </>
  );
};

Dropzone.propTypes = {
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  onDrop: PropTypes.func,
};

const IBISDropzone = ({ small, disabled = false }) => {
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      dispatch(
        actions.setCreateFileModal({
          open: true,
          initFiles: acceptedFiles,
        })
      );
    },
    [ dispatch ]
  );

  return (
    <div className="p-4 w-100">
      <Dropzone small={small} disabled={disabled} onDrop={onDrop} />
    </div>
  );
};

IBISDropzone.propTypes = {
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default IBISDropzone;
