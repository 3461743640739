import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col } from 'reactstrap';
import styled from 'styled-components';

import MainDisplay from './pages/mainDisplay';
import Sidebar from './pages/sidebar/sidebar';
import { topbarHeight } from './pages/topbar/topbar.styled';
import { sidebarWidth } from './pages/sidebar/sidebar.styled';
import Header from './components/nodes/header/headerContainer';

import FilterPanel from './components/filterPanel/FilterPanel.jsx';
import { useIBIS } from './hooks/useIbis';
import CustomPieChart from './components/charts/CustomPieChart.jsx';

const StyledMainContainer = styled.div`
  display: flex;
  min-height: calc(100vh - ${topbarHeight}px);
`;
const StyledContentContainer = styled(Col)`
  padding: 0;
  max-width: calc(100vw - ${({ sidebar }) => (sidebar ? 0 : sidebarWidth)}px);
  display: flex;
  flex-direction: column;
`;

const Main = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const disableSidebar = queryParams.get("sidebar") === "false";

  const IBIS = useIBIS(false);

  const dontShowSidebar =
    disableSidebar ||
    IBIS.node?.hints?.sidebar?.visibility === "hidden" ||
    IBIS.node?.hints?.sidebar?.visibility === "collapsed";

  return (
    <Container fluid className="p-0 m-0">
      <StyledMainContainer className="m-0">
        {!dontShowSidebar && <Sidebar />}
        <FilterPanel />
        <StyledContentContainer sidebar={dontShowSidebar}>
          <Header />
          <CustomPieChart />
          <MainDisplay toplevel={true} />
        </StyledContentContainer>
      </StyledMainContainer>
    </Container>
  );
};

Main.propTypes = {
  t: PropTypes.func, //HOC
  logIn: PropTypes.func,
  loginStore: PropTypes.object,
  entitiesStore: PropTypes.object,
};

export default Main;
