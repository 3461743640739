import React from 'react';
import PropTypes from 'prop-types';
import { BsCheck } from 'react-icons/bs';

import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  IconWrapper
} from './checkbox.js';

const Checkbox = ({ checked, onChange, outline, size, ...props }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} onChange={() => onChange()} {...props} />
    <StyledCheckbox checked={checked} onClick={() => onChange()} outline={outline} size={size}>
      <IconWrapper>
        {checked ? <BsCheck /> : null}
      </IconWrapper>
    </StyledCheckbox>
  </CheckboxContainer>
);

Checkbox.defaultProps = {
  size: 'sm',
  outline: false
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  outline: PropTypes.bool,
  size: PropTypes.oneOf([ 'sm', 'md' ])
};

export default Checkbox;
