import styled from 'styled-components';

import { theme } from '../../styles';

export const StyledTab = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.9rem 2rem 0.9rem 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${theme.colors.grey[700]};
  border-bottom: 0.4rem solid white;

  ${({ active }) => active ? `
    font-weight: 700;
    border-bottom: 0.4rem solid ${theme.colors.primary[500]};
  ` : ""}
`;


export const StyledTabContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.grey[50]};
`;


