import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyledFile, StyledRow, StyledEditButton } from './fileField.styled';

import { Button } from '../button/button';
import { ReactComponent as Edit } from '../../../assets/svg/icons/edit.svg';
import { ReactComponent as Check } from '../../../assets/svg/icons/check.svg';
import { ReactComponent as Cross } from '../../../assets/svg/icons/cross.svg';

const FileField = ({
  file,
  index,
  setFiles,
  createNewElFromURL,
  fileName,
  editFileModal,
}) => {
  const [ isInEditMode, setIsInEditMode ] = useState(false);

  const changeName = (index, name) => {
    setFiles((files) =>
      files.map((file, i) =>
        i === index
          ? Object.defineProperty(file, "name", {
            writable: true,
            value: name,
          })
          : file
      )
    );
  };

  const deleteFile = (index) => {
    setFiles((files) => files.filter((_, i) => i !== index));
  };

  const dirPath = (file.path ?? "/" + file.webkitRelativePath)
    ?.split("/")
    .slice(1, -1)
    .join("/");
  return (
    <StyledRow className="mt-2">
      {!isInEditMode && !createNewElFromURL && !editFileModal && (
        <Edit className="ml-2" />
      )}
      {!createNewElFromURL && !editFileModal ? (
        <>
          <span className="pl-2 font-weight-bold text-secondary">
            {dirPath && `${dirPath}/`}
          </span>
          <StyledFile
            className="pl-2"
            onFocus={() => setIsInEditMode(true)}
            onBlur={() => setIsInEditMode(false)}
            value={createNewElFromURL ? fileName : file.name}
            onChange={(e) => changeName(index, e.target.value)}
          />
        </>
      ) : (
        <span className="pl-1">{fileName}</span>
      )}

      {isInEditMode ? (
        <StyledEditButton
          onClick={() => setIsInEditMode(false)}
          className="mr-1"
          type="menu"
          icon={<Check />}
        ></StyledEditButton>
      ) : (
        !editFileModal && (
          <Button
            onClick={() => deleteFile(index)}
            className="ml-auto mr-1"
            type="menu"
            icon={<Cross />}
          ></Button>
        )
      )}
    </StyledRow>
  );
};

FileField.propTypes = {
  file: PropTypes.object,
  index: PropTypes.number,
  setFiles: PropTypes.func,
  fileName: PropTypes.string,
  createNewElFromURL: PropTypes.bool,
  editFileModal: PropTypes.bool,
};

export default FileField;
