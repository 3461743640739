import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button } from 'reactstrap';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { MdExpand } from 'react-icons/md';
import { success, error } from '../../main/utils/notify';

const clampedTextCSS = css`
  overflow-wrap: break-word;
  ${({ clamped }) => {
    if (clamped) {
      return `
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }
  }}
`;

const StyledClampedText = styled.div`
  ${clampedTextCSS}
`;
const StyledClampedPre = styled.pre`
  ${clampedTextCSS}
`;

const StyledTextContainer = styled.div`
  width: calc(100% - 70px);
`;

const ClampedText = ({ children = "null", json = false }) => {
  const [ clamped, setClamped ] = useState(true);

  return (
    <div className="d-flex">
      <StyledTextContainer>
        {json ? (
          <StyledClampedPre clamped={clamped}>{children}</StyledClampedPre>
        ) : (
          <StyledClampedText clamped={clamped}>{children}</StyledClampedText>
        )}
      </StyledTextContainer>
      <div style={{ width: "70px" }}>
        <Button
          className="p-1 m-1"
          disabled={!navigator.clipboard}
          onClick={() => {
            navigator.clipboard.writeText(children).then(
              () => {
                success("Copied to clipboard");
              },
              () => {
                error("Failed to copy to clipboard");
              }
            );
          }}
        >
          <HiOutlineClipboardCopy />
        </Button>
        <Button className="p-1 m-1" onClick={() => setClamped(!clamped)}>
          <MdExpand />
        </Button>
      </div>
    </div>
  );
};

ClampedText.propTypes = {
  children: PropTypes.string,
  json: PropTypes.bool,
};

export default ClampedText;
