import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, ModalFooter, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import Loading from '../../../main/components/loading';
import { Button } from '../../components/button/button';
import { Input } from '../../components/input/input';
import {
  StyledModalBody,
  RequiredStar,
  RequiredStarText,
  CustomSwitch,
  CustomSwitchRound,
  StyledListingWrapper,
  StyledWrappedRow,
} from './nodeCreator.styled';
import useIBIS from '../../hooks/useIbis';
import FilterPanel from '../filterPanel/FilterPanel.jsx';
import { __env } from '../../../envloader';
import MovableModal from './movableModal.jsx';
import { NodesRow } from '../nodes/nodesListing/nodesRow/nodesRow';
import BreadCrumbs from '../nodes/header/breadcrumbs/breadCrumbs';

const ListingWrapper = ({ customUrl }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [ startPath, setStartPath ] = useState(customUrl ?? decodeURIComponent(location.pathname));

  const IBIS = useIBIS(startPath !== decodeURIComponent(location.pathname), startPath);

  if (!IBIS.node && IBIS.isLoading) {
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <div>
          <Row className="justify-content-center">
            <Loading />
          </Row>
          <h6>{t("ibis-ui:loading")}</h6>
        </div>
      </Row>
    );
  }

  if (IBIS.error)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>
          {IBIS.error.response?.status === 403
            ? t("ibis-ui:forbidden")
            : IBIS.error.response?.status === 404
              ? t("ibis-ui:directory_not_found")
              : t("ibis-ui:backend_error")}
        </h6>
      </Row>
    );

  if (!IBIS.node)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>{t("ibis-ui:directory_not_found")}</h6>
      </Row>
    );

  const tenantName =decodeURIComponent(window.location.pathname).slice(1).split("/")[0];
  return (
    <StyledListingWrapper>
      <div className="pl-3">
        <BreadCrumbs
          setStartPath={setStartPath}
          startPath={"/" + tenantName + startPath}
        />
      </div>
      <StyledWrappedRow>
        <NodesRow
          startPath={startPath}
          setStartPath={setStartPath}
          resources={IBIS.node.items}
          loadMore={IBIS.loadMore}
          fileProvider={true}
        />
      </StyledWrappedRow>
    </StyledListingWrapper>
  );
};

ListingWrapper.propTypes = {
  customUrl: PropTypes.string,
};

const SymlinkCreator = ({ actionData, modal, closeModal, filter }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const IBIS = useIBIS();

  const [ active, setActive ] = useState(false);
  const [ name, setName ] = useState("");
  const [ target, setTarget ] = useState("");

  const [ targetPickerOpen, setTargetPickerOpen ] = useState(false);
  const [ relation, setRelation ] = useState("");
  const [ rellink, setRellink ] = useState(false);
  const pathnameForFolderFromURLCreation =
    pathname.slice(0, pathname.indexOf(name)) || "";

  const url = window.location.href + "?fileProvider=true";

  useEffect(() => {
    setTarget(filter?.initial_target ?? "");
  }, [ filter?.initial_target ]);

  useEffect(() => {
    if (typeof actionData?.path === "string") {
      setName(actionData?.path);
    }
    else if (actionData?.path?.[0]?.random) {
      setName(uuidv4());
    }
    else {
      setName("");
    }
  }, [ actionData ]);

  const close = () => {
    setName("");
    setRelation("");
    setRellink(false);
    closeModal();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleTargetChange = (event) => {
    setTarget(event.target.value);
  };

  const handleRelationChange = (event) => {
    setRelation(event.target.value);
  };

  const submit = () => {
    if (name.length > 0) {
      if (rellink)
        IBIS.createRellink({
          path: !IBIS.node
            ? pathnameForFolderFromURLCreation + name
            : pathname + name,
          target,
          relation: relation,
        }).finally(() => {
          close();
        });
      else
        IBIS.createSymlink({
          path: !IBIS.node
            ? pathnameForFolderFromURLCreation + name
            : pathname + name,
          target,
        }).finally(() => {
          close();
        });
    }
  };

  window.onmessage = function (e) {
    if (
      e.origin === new URL(url).origin &&
      typeof e.data === "string" &&
      e.data.includes(__env.IBIS_TENANT_URL)
    ) {
      setTarget("/" + e.data.replace(__env.IBIS_TENANT_URL, ""));
      setTargetPickerOpen(false);
    }
  };

  return (
    <MovableModal
      title={t("ibis-ui:create_symlink")}
      modal={modal}
      closeModal={close}
      xl={!!filter}
    >
      <StyledModalBody className="p-3">
        <Container>
          <Row>
            <label htmlFor="name">
              {t("ibis-ui:symlink_name")} <RequiredStar>*</RequiredStar>
            </label>
            <Input
              disabled={!IBIS.node}
              name="name"
              value={name}
              onChange={handleNameChange}
              readOnly={
                typeof actionData?.path === "string" ||
                actionData?.path?.[0]?.random
              }
            />
          </Row>
          {!!filter ? (
            <Row className="pt-3">
              <label>
                {t("ibis-ui:filters.filters")} <RequiredStar>*</RequiredStar>
              </label>
              <FilterPanel
                customUrl={filter?.initial_target}
                setTarget={setTarget}
                modal={true}
              />
            </Row>
          ) : (
            <Row className="pt-3">
              <label htmlFor="target">
                {t("ibis-ui:symlink_target")} <RequiredStar>*</RequiredStar>
              </label>
              {targetPickerOpen ? (
                <ListingWrapper />
              ) : (
                <div className="d-flex w-100 justify-content-between">
                  <Input
                    name="target"
                    value={target}
                    onChange={handleTargetChange}
                  />
                  <Button
                    onClick={() => setTargetPickerOpen(true)}
                    className="ml-2 mb-2"
                  >
                    {t("ibis-ui:pick")}
                  </Button>
                </div>
              )}
            </Row>
          )}
          <Row>
            {targetPickerOpen && (
              <Button
                onClick={() => setTargetPickerOpen(false)}
                className="my-3"
              >
                {t("ibis-ui:cancel")}
              </Button>
            )}
          </Row>
          <Row className="d-flex align-items-center pt-3">
            <label className="pr-3 py-2 m-0">{t("ibis-ui:rellink")}</label>
            <CustomSwitch
              onClick={() => {
                setActive(!active);
                setRellink(!active);
              }}
              active={active}
              type="switch"
              id="collapse-rellink"
              name="collapse-rellink"
            >
              <CustomSwitchRound active={active} />
            </CustomSwitch>
          </Row>
          {rellink && (
            <Row className="pt-3">
              <label htmlFor="relation">
                {t("ibis-ui:symlink_relation")} <RequiredStar>*</RequiredStar>
              </label>
              <Input
                name="relation"
                value={relation}
                onChange={handleRelationChange}
              />
            </Row>
          )}
        </Container>
      </StyledModalBody>
      <ModalFooter className="p-3">
        <Row className="m-0 w-100 d-flex justify-content-between">
          <Col className="p-0 d-flex align-items-center">
            <RequiredStar>*&nbsp;</RequiredStar>
            <RequiredStarText>{t("ibis-ui:required_fields")}</RequiredStarText>
          </Col>
          <Col className="p-0 d-flex justify-content-end">
            <Button type="secondary" onClick={close} className="mr-4">
              {t("ibis-ui:cancel")}
            </Button>
            <Button
              disabled={
                name.length === 0 ||
                target.length === 0 ||
                (rellink && relation.length === 0)
              }
              onClick={submit}
            >
              {t("ibis-ui:create_symlink")}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </MovableModal>
  );
};

SymlinkCreator.propTypes = {
  actionData: PropTypes.object,
  modal: PropTypes.bool,
  closeModal: PropTypes.func,
  mode: PropTypes.string,
  relationLink: PropTypes.bool,
  filter: PropTypes.object,
};

export default SymlinkCreator;
