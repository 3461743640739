import styled from 'styled-components';
import { theme } from '../../styles/index';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${({ size }) => size === 'sm' ? `${theme.fontSize.large}` : '35px'};
  height: ${({ size }) => size === 'sm' ? `${theme.fontSize.large}` : '35px'};
  background: ${({ checked, outline }) => (checked && !outline) ? `${theme.colors.primary["500"]}` : `${theme.colors.white}`};
  border-radius: 4px;
  box-shadow: ${({ checked, outline }) => (checked && !outline) 
    ? "0px 0px 0px rgba(0, 120, 133, 1), 0px 1px 1px rgba(0, 0, 0, 0.10)"
    : "0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10)"};
  transition: all 150ms;

  ${HiddenCheckbox}:disabled + & {
    background: #F1F2F2;
    svg {
      color: ${theme.colors.white};
    }
  }

  svg {
    color: ${({ outline }) => outline ? "blue" : `${theme.colors.white}`};
    font-size: ${({ size }) => size === 'sm' ? '1.25rem' : '1.875rem'};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;