import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { HashRouter } from 'react-router-dom';

import { StyledLinkButton, StyledLink, StyledIndentation } from './nodeSelector.styled';
import { useIBIS } from '../../../hooks/useIbis';
import { ReactComponent as Right } from '../../../../assets/svg/icons/right.svg';
import { ReactComponent as Down } from '../../../../assets/svg/icons/down.svg';

export const NodeName = ({ name, link }) => (
  <HashRouter hashType="noslash">
    <StyledLink to={"/" + link ?? name}>
      <Row className="m-0">{name}</Row>
    </StyledLink>
  </HashRouter>
);

NodeName.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
};

export const NodeSelector = ({
  name,
  isDirectory,
  setSelected,
  unravel = false,
}) => {
  const IBIS = useIBIS(
    true,
    name ? name + (isDirectory ? "/" : "") : undefined,
    true
  );

  if (IBIS.error) return null;

  const displayName = () => {
    const empty = !IBIS.node?.items || IBIS.node?.items.length === 0;
    return (
      <>
        {isDirectory ? (
          <StyledLinkButton
            loading={IBIS.isLoading ? 1 : 0}
            onClick={() => setSelected(name)}
          >
            <div className="d-flex align-items-center">
              {name}
              {!empty && (unravel ? <Down /> : <Right />)}
            </div>
          </StyledLinkButton>
        ) : (
          <NodeName name={name}/>
        )}
      </>
    );
  };
  if (!unravel || !IBIS.node?.items) return displayName();

  return (
    <>
      {displayName()}
      {IBIS.node.items.map((node) => {
        return (
          <StyledIndentation>
            <HashRouter hashType="noslash">
              <Link
                to={
                  (name ? "/" + name : "") +
                  "/" +
                  node.name +
                  (node.type === "directory" ? "/" : "")
                }
              >
                <Row className="m-0">{node.name}</Row>
              </Link>
            </HashRouter>
          </StyledIndentation>
        );
      })}
    </>
  );
};

NodeSelector.propTypes = {
  name: PropTypes.string,
  isDirectory: PropTypes.bool,
  unravel: PropTypes.bool,
  setSelected: PropTypes.func,
};
