import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RJSFForm from '@rjsf/bootstrap-4';
import applyNavs from 'react-jsonschema-form-pagination-bs4';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import { processForm, validateForm } from './formUtils';
import CustomNavs from './customNavs';

const Form = ({
  submitRef,
  schema,
  uiSchema,
  formData,
  onSubmit,
  onError,
  hasNavs = false,
  disabled = false,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  const [ state, setState ] = useState(null);

  useEffect(() => {
    setState(processForm(schema, uiSchema, schema, uiSchema, formData));
  }, [ schema, uiSchema, formData ]);

  const validate = (formData, errors) => {
    return validateForm(state.uiSchema, formData, errors);
  };

  const handleChange = (data) => {
    setState(
      processForm(schema, uiSchema, state.schema, state.uiSchema, data.formData)
    );
  };

  const transformErrors = useCallback(
    (errors) => {
      return errors.map((error) => {
        const key = `form.errors.${error.name}`;
        const limit = error?.params?.limit;

        if (i18n.exists(key)) {
          error.message = t(key, { limit });
        }

        return error;
      });
    },
    [ t, i18n ]
  );

  const FormwithNavs = hasNavs ? applyNavs(RJSFForm, CustomNavs) : RJSFForm;

  if (!state) return null;

  return (
    <FormwithNavs
      schema={state.schema}
      uiSchema={state.uiSchema}
      formData={state.formData}
      onSubmit={onSubmit}
      onError={onError}
      transformErrors={transformErrors}
      onChange={handleChange}
      validate={validate}
      disabled={disabled}
      {...props}
    >
      <Button
        innerRef={(el) => {
          if (submitRef) submitRef.current = el;
        }}
        disabled={disabled}
        hidden={submitRef}
      >
        {t("submit")}
      </Button>
    </FormwithNavs>
  );
};

Form.propTypes = {
  schema: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  uiSchema: PropTypes.object,
  onSubmit: PropTypes.func,
  hasNavs: PropTypes.bool,
  onError: PropTypes.func,
  widgets: PropTypes.object,
  fields: PropTypes.object,
  disabled: PropTypes.bool,
  submitRef: PropTypes.object,
};

export default Form;
