import React from 'react';
import PropTypes from 'prop-types';
import Editor from '@monaco-editor/react';
import ReactMarkdown from 'react-markdown';

const Markdown = ({ content, isEditing, editorRef }) => {
  return (
    <>
      {isEditing ? (
        <Editor
          onMount={(editor) => {
            editorRef.current = editor;
          }}
          defaultLanguage="markdown"
          value={content}
        />
      ) : (
        <ReactMarkdown>{content}</ReactMarkdown>
      )}
    </>
  );
};

Markdown.propTypes = {
  content: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  editorRef: PropTypes.object.isRequired,
};

export default Markdown;
