import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import NodeContainer from '../../nodeContainer/nodeContainer';
import {
  StyledNodesList,
  StyledHeader,
  StyledTableRow,
  StyledTableHead,
} from './nodesRow.styled';
import { NodesRowItem } from './nodesRowItem';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';
import { parseHints } from '../../../../utils/hints';

export const NodesRow = ({
  resources,
  startPath,
  mode,
  setStartPath,
  fileProvider,
}) => {
  const { t } = useTranslation();
  const { lastElementRef } = useInfiniteScroll();

  const columns = parseHints(
    mode?.columns
      ? mode?.columns
      : fileProvider
        ? {
          name: {
            header: t("ibis-ui:name"),
            priority: 0,
            cell: {
              property: "name",
              type: "property",
            },
          },
          type: {
            header: t("ibis-ui:content_type"),
            priority: 2,
            cell: {
              property: "content_type",
              type: "property",
            },
          },
          modified: {
            header: t("ibis-ui:last_modified"),
            priority: 3,
            cell: {
              property: "modified",
              type: "property",
            },
          },
        }
        : {
          name: {
            header: t("ibis-ui:name"),
            priority: 0,
            cell: {
              property: "name",
              type: "property",
            },
          },
          type: {
            header: t("ibis-ui:content_type"),
            priority: 1,
            cell: {
              property: "content_type",
              type: "property",
            },
          },
          size: {
            header: t("ibis-ui:size"),
            priority: 2,
            cell: {
              property: "size",
              type: "property",
            },
          },
          modified: {
            header: t("ibis-ui:last_modified"),
            priority: 3,
            cell: {
              property: "modified",
              type: "property",
            },
          },
          attributes: {
            header: t("ibis-ui:attributes"),
            priority: 3,
            cell: {
              property: "attributes",
              type: "property",
            },
          },
        }
  );


  const renderList = resources.map((node, index) => {
    return (
      <NodeContainer
        key={node.name + node.type}
        type={node.type}
        name={node.name}
        target={node.target}
        startPath={startPath}
        setStartPath={setStartPath}
        fileProvider={fileProvider}
      >
        <NodesRowItem
          innerRef={index === resources.length - 1 ? lastElementRef : null}
          {...node}
          node={node}
          data={columns.map((column) => {
            return {
              ...column,
            };
          })}
        >
        </NodesRowItem>
      </NodeContainer>
    );
  });

  return (
    <>
      <StyledNodesList>
        <StyledTableHead>
          <StyledTableRow>
            <StyledHeader size="sm">
            </StyledHeader>
            {columns.map((column) => {
              return (
                <StyledHeader key={column.header} size="md">
                  {column.header}
                </StyledHeader>
              );
            })}
            {fileProvider ? null : <StyledHeader></StyledHeader>}
          </StyledTableRow>
        </StyledTableHead>
        {renderList}
      </StyledNodesList>
    </>
  );
};

NodesRow.propTypes = {
  resources: PropTypes.array,
  mode: PropTypes.object,
  startPath: PropTypes.string,
  setStartPath: PropTypes.func,
  fileProvider: PropTypes.bool,
};
