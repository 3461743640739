import React from 'react';
import PropTypes from 'prop-types';
import Editor from '@monaco-editor/react';
import { StyledIframe } from './displayFile.styled';
import { __env } from '../../../../envloader';

const Embed = ({
  isLoading,
  content,
  location,
  isEditing,
  editorRef,
  getEmbedContent,
}) => {
  React.useEffect(() => {
    if (!location && !isLoading) getEmbedContent();
  }, [ getEmbedContent, location, isLoading ]);

  if (!location) return null;

  return isEditing ? (
    <Editor
      onMount={(editor) => {
        editorRef.current = editor;
      }}
      defaultLanguage="html"
      defaultValue={content}
    />
  ) : (
    <StyledIframe
      src={
        !location.startsWith("http://") && !location.startsWith("https://")
          ? `${new URL(__env.IBIS_TENANT_URL).origin}${location}`
          : location
      }
      title="HTML"
      sandbox="allow-same-origin allow-scripts allow-downloads"
      contentsecuritypolicy="default-src"
    />
  );
};

Embed.propTypes = {
  content: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  editorRef: PropTypes.object.isRequired,
  location: PropTypes.string,
  getEmbedContent: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Embed;
