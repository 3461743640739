import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';

import { removeMeta } from '../../../utils/urlHelpers.js';
import BreadCrumbs from './breadcrumbs/breadCrumbs.jsx';
import Attributes from '../attributes/attributes.jsx';
import { useIBIS } from '../../../hooks/useIbis.js';
import Opsbar from '../../hints/opsbar.jsx';
import Header from './header.jsx';

const HeaderContainer = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const disableAddressbar = queryParams.get("addressbar") === "false";
  const disableInfobar = queryParams.get("infobar") === "false";

  const IBIS = useIBIS(false);
  const { pathname } = useLocation();
  const path = pathname.slice(1);

  const pathnameArray = removeMeta(decodeURIComponent(window.location.pathname).slice(1)).split(
    "/"
  );

  const name = pathnameArray.slice(-1)[0]
    ? pathnameArray.slice(-1)[0]
    : pathnameArray.slice(-2)[0];

  const isCurrentPathMeta = path !== removeMeta(path);
  const currentViewName = isCurrentPathMeta ? path.split("/").slice(-2)[0] : "";

  const headers = useMemo(() => {
    const viewsArray =
      IBIS.node?.hints?.headers &&
      Object.entries(IBIS.node?.hints?.headers).filter(
        ([ _, view ]) => view?.type
      );
    if (viewsArray) {
      viewsArray.sort((a, b) => {
        if (!a[1]?.priority) return -1;
        if (!b[1]?.priority) return 1;
        return a[1].priority - b[1].priority;
      });
    }

    return viewsArray ?? [];
  }, [ IBIS.node?.hints?.headers ]);

  return (
    <Container fluid className="px-3">
      {disableAddressbar ||
      IBIS.node?.hints?.addressbar?.visibility === "hidden" ||
      IBIS.node?.hints?.addressbar?.visibility === "collapsed" ? null : (
          <Row className="m-0">
            <BreadCrumbs />
          </Row>
        )}
      {disableInfobar ||
      IBIS.node?.hints?.infobar?.visibility === "hidden" ||
      IBIS.node?.hints?.infobar?.visibility === "collapsed" ? null : (
          <>
            <Row className="m-0">
              <Col sm="6">
                <Row>
                  <h3 className="py-2 m-0">
                    <b>{name}</b>
                  </h3>
                </Row>
                <Row>
                  <h3>
                    <b>{currentViewName}</b>
                  </h3>
                </Row>
              </Col>
              <Col sm="6">
                <span className="float-right d-flex">
                  <Opsbar />
                </span>
              </Col>
            </Row>
            <Row className="m-0">
              <Attributes isHeader={true} />
            </Row>
          </>
        )}
      {headers.map((header) => (
        <Row key={header[0]}>
          <Col>
            <Header startPath={header[1].path} view={header[1].type} />
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default HeaderContainer;
