import styled from 'styled-components';

import { theme } from '../../styles';

export const StyledDropdown = styled.div`
  position: absolute;
  left: 0%;
  background: ${theme.colors.grey[0]};
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
    0px 10px 15px -3px rgba(17, 24, 38, 0.1),
    0px 5px 10px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  z-index: 5;
`;

export const StyledDropdownButton = styled.button`
  all:unset;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledDropdownItem = styled.div`
  display: flex;
  align-items: center;
      border-radius: 4px;
    color: ${({ type }) => {
    switch (type) {
    case "destructive":
      return theme.colors.red["500"];
    default:
      return theme.colors.grey["700"];
    }
  }};
    ${({ type }) => {
    switch (type) {
    case "destructive":
      return `path,
      circle {
        stroke: ${theme.colors.red["500"]};
      };`;
    default:
      return "";
    }
  }};
    :hover {
      background-color: ${({ type }) => {
    switch (type) {
    case "destructive":
      return theme.colors.red["600"];
    default:
      return theme.colors.primary["50"];
    }
  }};
      ${({ type }) => {
    switch (type) {
    case "destructive":
      return `path,
          circle {
            stroke: white;
          };`;
    default:
      return "";
    }
  }};

      color: ${({ type }) => {
    switch (type) {
    case "destructive":
      return "white";
    default:
      return theme.colors.grey["700"];
    }
  }};
    }
    :active {
      background-color: ${({ type }) => {
    switch (type) {
    case "destructive":
      return theme.colors.red["500"];
    default:
      return theme.colors.primary["500"];
    }
  }};
      color: white;
      path,
      circle {
        stroke: white;
      };
    }

  font-size: 14px;
  font-weight: 500;

  * {
    z-index: 1;
  }
`;

export const StyledDropdownIcon = styled.div`
  display: flex;
  height: 24px;
  svg {
    widht: 12px;
    height: 12px;
    color: ${theme.colors.primary[400]};
  }
`;
