import * as nodesActions from './nodes';
import * as cacheActions from './cache';
import * as mappingActions from './mapping';
import * as uiActions from './ui';

export const namespace = '@IBIS_INTERNAL/';

export const SET_NODE = namespace + 'SET_NODE';
export const EXPAND_NODE = namespace + 'EXPAND_NODE';
export const CLEAR_NODE = namespace + 'CLEAR_NODE';
export const SET_ERROR = namespace + 'SET_ERROR';
export const SET_LOADING = namespace + 'SET_LOADING';
export const APPLY_PATCH = namespace + 'APPLY_PATCH';
export const UPDATE_CACHE = namespace + 'UPDATE_CACHE';
export const SET_MAPPING = namespace + 'SET_MAPPING';
export const SET_CREATE_FILE_MODAL = namespace + 'SET_CREATE_FILE_MODAL';


const actions = {
  ...nodesActions,
  ...cacheActions,
  ...mappingActions,
  ...uiActions,
};
export default actions;
