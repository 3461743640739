import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from '../../../../components/button/button';
import { StyledDropdownIcon, StyledDropdownButton } from '../../../../components/dropdown/dropdown.styled';
import Dropdown from '../../../../components/dropdown/dropdown';
import { ReactComponent as Add } from '../../../../../assets/svg/icons/add.svg';
import { ReactComponent as Folder } from '../../../../../assets/svg/icons/folder.svg';
import { ReactComponent as File } from '../../../../../assets/svg/icons/file.svg';
import { ReactComponent as Link } from '../../../../../assets/svg/icons/link.svg';

const AddNode = ({ openFileModal, openDirectoryModal, openSymlinkModal, createNewElFromURL }) => {
  const node = useRef();

  const [ dropdown, setDropdown ] = useState(false);
  const { t } = useTranslation();

  return (
    <div ref={node} className='position-relative'>
      <Button svgProperty='stroke' icon={<Add />} onClick={() => setDropdown(!dropdown)}>
        {createNewElFromURL ? t("ibis-ui:create_new") : t("ibis-ui:add_new")}
      </Button>
      {dropdown && (
        <Dropdown triggerRef={node} changeDropdownState={setDropdown} className="mt-2 p-2">
          <StyledDropdownButton onClick={openDirectoryModal} className='p-1'>
            <StyledDropdownIcon className="pr-2">
              <Folder className="align-self-center" />
            </StyledDropdownIcon>
            {t("ibis-ui:folder")}
          </StyledDropdownButton>
          <StyledDropdownButton onClick={openFileModal} className='p-1'>
            <StyledDropdownIcon className="pr-2">
              <File className="align-self-center" />
            </StyledDropdownIcon>
            {t("ibis-ui:file")}
          </StyledDropdownButton>
          <StyledDropdownButton onClick={openSymlinkModal} className='p-1'>
            <StyledDropdownIcon className="pr-2">
              <Link className="align-self-center" />
            </StyledDropdownIcon>
            {t("ibis-ui:symlink")}
          </StyledDropdownButton>
        </Dropdown>
      )}
    </div>
  );
};

AddNode.propTypes = {
  openFileModal: PropTypes.func.isRequired,
  openDirectoryModal: PropTypes.func.isRequired,
  createNewElFromURL: PropTypes.bool,
  openSymlinkModal: PropTypes.func.isRequired,
};

export default AddNode;
