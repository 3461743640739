import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Map } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useLocation } from 'react-router';
import IBIS from '../interface-actions/index';
import { tenantPath } from '../../main/utils/authUtils';

export const useIBIS = (
  fetchOnInit = false,
  customPathname = null,
  fetchAll = false
) => {
  const initialState = Map({
    isLoading: fetchOnInit,
    error: null,
    node: null,
  });
  
  const selectJsState = createSelector(
    (state) => state.IBIS.nodes,
    (_, path) => path,
    (IBIS, path) => {
      return IBIS.get(path) ? IBIS.get(path).toJS() : initialState.toJS();
    }
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname: locationPathname } = useLocation();
  const oldPathname = customPathname ?? locationPathname;
  const pathname =
    (decodeURIComponent(window.location.pathname).replace(tenantPath, "/") === locationPathname
      ? oldPathname
      : decodeURIComponent(window.location.pathname).replace(tenantPath, "/") + oldPathname.slice(1));
  const isDirectory = pathname.endsWith("/");

  const state = useSelector((state) => selectJsState(state, pathname));

  const fetchData = useCallback(async () => {
    if (isDirectory)
      dispatch(
        IBIS.fetchDirectory({ path: pathname, replace: true, fetchAll })
      ).then((res) => {
        if (res?.headers && res?.headers["ibis-type"] === "symlink")
          history.replace(res?.headers["ibis-target"] + window.location.search);
        if ((res?.headers && res?.headers["ibis-type"] === "file") || res?.response?.data?.code === "NOT_A_DIRECTORY")
          history.replace(pathname.slice(0, -1) + window.location.search);
      });
    else
      dispatch(
        IBIS.fetchFile({ path: pathname, detectContentType: true })
      ).then((res) => {
        if (res?.headers && res?.headers["ibis-type"] === "symlink")
          history.replace(res?.headers["ibis-target"] + window.location.search);
        if (res?.headers && res?.headers["ibis-type"] === "directory")
          history.replace(pathname + "/" + window.location.search);
      });
  }, [ isDirectory, dispatch, pathname, fetchAll, history ]);

  const fetchMore = useCallback(async () => {
    dispatch(IBIS.fetchDirectory({ path: pathname, cursor: state.node?.more }));
  }, [ dispatch, pathname, state.node?.more ]);

  useEffect(() => {
    if (state.error?.response?.data?.code === "INVALID_NODE_TYPE")
      history.push(pathname.slice(0, -1) + window.location.search);
  }, [ state.error, history, pathname ]);

  useEffect(() => {
    if (fetchOnInit) {
      fetchData();
      return () => {
        dispatch(IBIS.clearNode({ path: pathname }));
      };
    };
  }, [ dispatch, fetchData, fetchOnInit, pathname ]);


  const controls = (action) => {
    const actionKeys = Object.keys(action);

    actionKeys.forEach((key) => {
      if (key === "navigate")
        history.push(action[key].path);

      if (key === "touch")
        IBIS.touch(locationPathname + action[key].path).then(() => fetchData());
    });
  };

  return {
    ...state,
    fetchData: fetchData,
    fetchMore: state.node?.more && fetchMore,
    delete: ({ path }) =>
      IBIS._delete({ path: path ?? pathname }).then(() =>
        fetchData()
      ),
    getEmbedContent: () =>
      dispatch(IBIS.getEmbedContent({ path: pathname })),
    createDirectory: ({ attributes, path }) =>
      IBIS.createDirectory({ path: path ?? pathname, attributes }).then(() =>
        fetchData()
      ),
    createSymlink: ({ target, path }) =>
      IBIS.createSymlink({ path: path ?? pathname, target }).then(() =>
        fetchData()
      ),
    createRellink: ({ target, path, relation }) =>
      IBIS.createRellink({ path: path ?? pathname, target, relation }).then(() =>
        fetchData()
      ),
    createDirectoryWithoutUpdating: ({ attributes, path }) =>
      IBIS.createDirectory({ path: path ?? pathname, attributes }),
    createFile: ({ file, path, onUploadProgress, signal }) =>
      IBIS.createFile({
        path: path ?? pathname,
        file,
        onUploadProgress,
        signal,
      }).then(() => fetchData()),
    patchFile: ({ file, path, onUploadProgress, signal }) =>
      IBIS.patchFile({
        path: path ?? pathname,
        file,
        onUploadProgress,
        signal,
      }).then(() => fetchData()),
    patchDirectory: ({ attributes, path }) =>
      IBIS.patchDirectory({ path: path ?? pathname, attributes }).then(() =>
        fetchData()
      ),
    controls
  };
};

export default useIBIS;
