import React from 'react';
import PropTypes from 'prop-types';

import { StyledImage } from './displayFile.styled';

const Image = ({ content }) => {
  return (
    <div className="h-100 d-flex flex-column">
      <StyledImage src={URL.createObjectURL(content)} alt={"None"} />
    </div>
  );
};

Image.propTypes = {
  content: PropTypes.object.isRequired,
};

export default Image;
