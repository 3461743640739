import React from 'react';
import PropTypes from 'prop-types';

import { NodesRow } from './nodesRow/nodesRow';
import { NodesGrid } from './nodesGrid/nodesGrid';

export const NodesListing = ({ startPath, resources, loadMore, mode }) => {
  return mode?.type === "tiles" ? (
    <NodesGrid
      startPath={startPath}
      resources={resources}
      loadMore={loadMore}
    />
  ) : (
    <NodesRow
      startPath={startPath}
      resources={resources}
      loadMore={loadMore}
      mode={mode}
    />
  );
};

NodesListing.propTypes = {
  resources: PropTypes.array,
  startPath: PropTypes.string,
  loadMore: PropTypes.func,
  mode: PropTypes.object,
};
