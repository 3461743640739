import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IdTokenVerifier from 'idtoken-verifier';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import { __env } from '../../envloader';
import ClampedText from './clampedText';
import AccessTokenProvider from '../utils/axios/accessTokenProvider';
import { getSS } from '../utils/sessionStorageProvider';

const accessTokenProvider = new AccessTokenProvider();

const Debug = () => {
  const [ modal, setModal ] = useState(false);
  const FREEZE_KEY = "D";

  const loginData = useSelector((state) => state.login.toJS().loginData) ?? {};

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.ctrlKey && e.altKey && e.shiftKey && e.key === FREEZE_KEY) {
        setModal(!modal);
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  const endpointConfig = JSON.parse(getSS("endpoint_config"));
  const verifier = new IdTokenVerifier({
    issuer: endpointConfig?.AUTH_ISSUER,
    audience: __env.AUTH_CLIENT_ID,
    jwksURI: endpointConfig?.AUTH_JWKS_URI,
  });

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      size="lg"
      fade={false}
    >
      <ModalHeader>Debug panel</ModalHeader>
      <ModalBody>
        <Row className="p-2">
          <Col className="p-0" sm={3}>
            <h6>Username:</h6>
          </Col>
          <Col className="d-flex align-items-center p-0" sm={9}>
            {loginData.username ?? "null"}
          </Col>
        </Row>

        <Row className="p-2">
          <Col className="p-0" sm={3}>
            <h6>Access token:</h6>
          </Col>
          <Col className="p-0" sm={9}>
            <ClampedText>{loginData.accessToken}</ClampedText>
          </Col>
        </Row>

        <Row className="p-2">
          <Col className="p-0" sm={3}>
            <h6>Refresh token:</h6>
          </Col>
          <Col className="p-0" sm={9}>
            <ClampedText>{loginData.refreshToken}</ClampedText>
          </Col>
        </Row>

        <Row className="p-2">
          <Col className="p-0" sm={3}></Col>
          <Col className="p-0" sm={9}>
            <Button onClick={() => accessTokenProvider._getAccessToken()}>
              Refresh
            </Button>
          </Col>
        </Row>

        <Row className="p-2">
          <Col className="p-0" sm={3}>
            <h6>IdP info:</h6>
          </Col>
          <Col className="p-0" sm={9}>
            <ClampedText json={true}>
              {JSON.stringify(JSON.parse(getSS("endpoint_config")), null, 2)}
            </ClampedText>
          </Col>
        </Row>

        <Row className="p-2">
          <Col className="p-0" sm={3}>
            <h6>Access token Decoded:</h6>
          </Col>
          <Col className="p-0" sm={9}>
            {loginData.accessToken && (
              <ClampedText json={true}>
                {JSON.stringify(
                  verifier.decode(loginData.accessToken),
                  null,
                  2
                )}
              </ClampedText>
            )}
          </Col>
        </Row>

        <Row className="p-2">
          <Col className="p-0" sm={3}>
            <h6>Refresh token Decoded:</h6>
          </Col>
          <Col className="p-0" sm={9}>
            {loginData.accessToken && (
              <ClampedText json={true}>
                {JSON.stringify(
                  verifier.decode(loginData.refreshToken),
                  null,
                  2
                )}
              </ClampedText>
            )}
          </Col>
        </Row>

        <Row className="p-2">
          <Col className="p-0" sm={3}>
            <h6>Envs:</h6>
          </Col>
          <Col className="p-0" sm={9}>
            <ClampedText json={true}>
              {JSON.stringify(__env, null, 2)}
            </ClampedText>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default Debug;
